import React, { Component } from "react";
import { Button, Jumbotron, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';

import HeaderSmall from './HeaderSmall';
import "./Signin.css";

export default class NotFound extends Component {
  render() {
    return(
      <div>
        <HeaderSmall/>
        <div className="header-center-title">
          <span>imageOpt</span>
        </div>
        <Container>
          <div className="page">
            <Jumbotron>
              <h1>Sorry!</h1>
              <p>
                We can't seem to find the page you were looking for. Please click the button below to go to our home page.
              </p>
              <p>
                <Link to="/">
                  <Button variant="warning">Home</Button>
                </Link>
              </p>
            </Jumbotron>
          </div>
        </Container>
      </div>
    );
  }
}
