import React, { Component } from 'react';
import { Container, Row, Col, Jumbotron } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Header from './Header';
import * as Config from './Config'
import "./Pricing.css";

export default class Pricing extends Component {
  render() {
    const { loggedIn, loggedInUser } = this.props;
    const email = Config.EMAIL_SALES;

    return (
      <>
        <Container>
          <Header
            loggedIn = {loggedIn}
            loggedInUser = {loggedInUser}
          />
        </Container>
       <div className="header-center-title">
        </div>
        <div className="pricing-content">
          <Container>
        <Jumbotron>
          <h1>Pricing</h1>
          <p><i>Signup in less that 30 seconds. Start with Forever Free plan, no credit card required, upgrade at any time. Please don't hesistate to contact us if you have queries on below plans or if you need customized plan.</i>
          </p>
          <Link style={{paddingTop:"8px", width:"200px"}} className="btn btn-warning" to="/signup">START FREE</Link>
        </Jumbotron>
 
            <Row>
              <Col md={3} className="pricing-column">
                <ul>
                  <li className="header-small">Forever</li>
                  <li className="header">FREE</li>
                  <li className="price"><span className="currency">$</span>0</li>
                  <li className="price-small">per month</li>
                  <li><span className="number">10</span> images</li>
                  <li className="details-big"><span className="number">1</span> GB Bandwidth Free</li>
                  <li className="details-small">per month</li>
                  <li className="details-big"><span className="number">5</span> Change Migrations Free</li>
                  <li className="details-small">per month</li>
                  <li className="additional-info">After free usage, charge of <b>20¢</b> per <b>GB</b> and <b>1¢</b> per <b>change migration</b> will apply </li>
                  <li className="action-btn">
                    <Link className="btn btn-warning btn-block" to="/signup">START FREE</Link>
                  </li>
                </ul>
              </Col>
              <Col md={3} className="pricing-column">
                <ul>
                  <li className="header-small">Pay As You Go</li>
                  <li className="header">100</li>
                  <li className="price"><span className="currency">$</span>5</li>
                  <li className="price-small">per month</li>
                  <li><span className="number">100</span> images</li>
                  <li className="details-big"><span className="number">10</span> GB Bandwidth Free</li>
                  <li className="details-small">per month</li>
                  <li className="details-big"><span className="number">100</span> Change Migrations Free</li>
                  <li className="details-small">per month</li>
                  <li className="additional-info">After free usage, charge of <b>20¢</b> per <b>GB</b> and <b>1¢</b> per <b>change migration</b> will apply </li>
                  <li className="action-btn">
                    <Link className="btn btn-warning btn-block" to="/signup/100">SIGN UP</Link>
                  </li>
                </ul>
              </Col>
              <Col md={3} className="pricing-column">
                <ul>
                  <li className="header-small">Pay As You Go</li>
                  <li className="header">500</li>
                  <li className="price"><span className="currency">$</span>20</li>
                  <li className="price-small">per month</li>
                  <li><span className="number">500</span> images</li>
                  <li className="details-big"><span className="number">50</span> GB Bandwidth Free</li>
                  <li className="details-small">per month</li>
                  <li className="details-big"><span className="number">500</span> Change Migrations Free</li>
                  <li className="details-small">per month</li>
                  <li className="additional-info">After free usage, charge of <b>20¢</b> per <b>GB</b> and <b>1¢</b> per <b>change migration</b> will apply </li>
                  <li className="action-btn">
                    <Link className="btn btn-warning btn-block" to="/signup/500">SIGN UP</Link>
                  </li>
                </ul>
              </Col>
              <Col md={3} className="pricing-column">
                <ul>
                  <li className="header-small">Pay As You Go</li>
                  <li className="header">ENTERPRISE</li>
                  <li className="price"><span className="currency">$</span>X</li>
                  <li className="price-small">per month</li>
                  <li><span className="number">n</span> images</li>
                  <li className="details-big"><span className="number">n</span> GB Bandwidth Free</li>
                  <li className="details-small">per month</li>
                  <li className="details-big"><span className="number">n</span> Change Migrations Free</li>
                  <li className="details-small">per month</li>
                  <li className="additional-info">Contact us with your <b>requirements</b> and we will quote the <b>best price</b></li>
                  <li className="action-btn">
                    <a className="btn btn-warning btn-block" href={"mailto:"+email}>CONTACT US</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
