import React, { Component } from 'react';
import { Button, Spinner } from "react-bootstrap";
import { Redirect } from 'react-router-dom';

import ApiService from './ApiService';
import ModalAlert from './ModalAlert';
import ImageSetForm from './ImageSetForm';
import Timer from './Timer';
import { ListOfImageSet } from './ImageSet';
import Header from './Header';
import * as Config from './Config';

import './App.css';

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      imageSets: [],
      loading: true,
      show: false,
      alertShow:false,
      alertTitle:"",
      alertDesc:"",
      commitingSets: [],
      commitRefreshing:false
    };

    this.fetchImageSets = this.fetchImageSets.bind(this);
    this.onAddImageSet = this.onAddImageSet.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onUpdated = this.onUpdated.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
    this.onRefreshCommiting = this.onRefreshCommiting.bind(this);

    if (this.props.loggedIn) {
      this.fetchImageSets();
    }
  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  filterCommitingSets(imageSets) {
    return imageSets
      .filter(
        el => el.freshness.status === "progressing"
      ).map(
        el => el._id
      );
  }

  fetchImageSets() {
    const { onLoginStatusChange } = this.props;
    ApiService.imageSet.fetch()
        .then(response =>  {
          this.setState({
            imageSets: response.data.data, 
            commitingSets: this.filterCommitingSets(response.data.data), 
            loading: false 
          });
        })
        .catch(error => {
            if (error.response) {
              if (error.response.status === 401
                   || error.response.status === 403
                   || error.response.status === 419) {
                this.showAlert("Oops!", "Not logged or Session Expired");
                onLoginStatusChange(false);
			  }
              else {
                this.showAlert("Oops!", error.response.data.message);
              }              
            }
			else {
              this.showAlert("Oops!", error.message);
            }
        });
  }

  onAddImageSet(imageSet) {
    const { onLoginStatusChange } = this.props;
    return new Promise((resolve, reject) => {
     var data = {
       name: imageSet.name,
       description: imageSet.description,
       enable_lang: imageSet.enable_lang,
     }

     if (imageSet.enable_lang) {
       data.default_lang = imageSet.default_lang;
     }

     ApiService.imageSet.create(data)
      .then(response =>  {
        const newImageSet = response.data.data
        const updatedImageSets = [newImageSet].concat(this.state.imageSets);
        this.setState({
          imageSets: updatedImageSets,
          show: false,
        });
		resolve(true);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401 
               || error.response.status === 403
               || error.response.status === 419) {
            reject("Not logged or Session Expired");
            onLoginStatusChange(false);
		  }
          else {
            reject(error.response.data.message);
          }
        }
		else {
          reject(error.message);
        }
      });
    });
  }

  onDelete(_id) {
    const { onLoginStatusChange } = this.props;
    return new Promise((resolve, reject) => {
      ApiService.imageSet.delete(_id)
      .then(response =>  {
        this.onDeleted(_id);
		resolve(true);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401 
               || error.response.status === 403
               || error.response.status === 419) {
            this.showAlert("Oops!", "Not logged or Session Expired");
            onLoginStatusChange(false);
          }
          else {
            this.showAlert("Oops!", error.response.data.message);
          }
        }
        else {
          this.showAlert("Oops!", error.message);
        }
		reject(error);
      });
    });
  }

  onDeleted(_id) {
    // Delete local copy
    this.setState({
      imageSets: this.state.imageSets.filter(
        el => el._id !== _id
      ),
      commitingSets: this.state.commitingSets.filter(
        el => el !== _id
      ),
    });
  }

  onUpdated(newImageSet) {
    const updatedImageSets =
      this.state.imageSets.map(
        el => (el._id === newImageSet._id ? newImageSet : el)
      );

    // Update commitingSets
    // First find if this element already exists in commitingSets
    var updatedCommitingSets;
    var commitingSetIndex = -1;
    if (this.state.commitingSets) {
      commitingSetIndex =
        this.state.commitingSets.findIndex(
          el => el === newImageSet._id
        );
    }

    if (commitingSetIndex > -1) {
      // if imageSet is present in the commiting list
      if (newImageSet.freshness.status !== "progressing") {
        // if the imageSet is done commiting remove from the list
        updatedCommitingSets = this.state.commitingSets.filter(
          el => el !== newImageSet._id
        );
      }
    }
    else {
      // if this imageSet not present in commitingSets
      if (newImageSet.freshness.status === "progressing") {
        // add to the list if this imageSet is being commited
        if (this.state.commitingSets) {
          updatedCommitingSets =
            [newImageSet._id].concat(this.state.commitingSets);
        }
        else {
          updatedCommitingSets = [newImageSet._id];
        }
      }
    }

    if (updatedCommitingSets) {
      this.setState({
        imageSets: updatedImageSets,
        commitingSets: updatedCommitingSets
      });
    }
    else {
      this.setState({
        imageSets: updatedImageSets,
      });
    }

  }

  onRefreshCommiting() {
    if (!this.state.commitRefreshing) {
      const { onLoginStatusChange } = this.props;
      const { commitingSets } = this.state;

      this.setState({ commitRefreshing:true});

      for(let i = 0; i < commitingSets.length; i++) {
        ApiService.imageSet.getCommitStatus(commitingSets[i])
        .then(response =>  {
          this.onUpdated(response.data.data);
        })
        .catch(error => {
            if (error.response) {
              if (error.response.status === 401
                   || error.response.status === 403
                   || error.response.status === 419) {
                this.showAlert("Oops!", "Not logged or Session Expired");
                onLoginStatusChange(false);
			  }
              else if (error.response.status === 404) {
                // if the imageSet is not found remove it from the list
                this.onDeleted(commitingSets[i]);
              }
              else {
                // Since this is happening in background just log
                // the error in console
                console.log(error.response.data.message);
                //this.showAlert("Oops!", error.response.data.message);
              }
            }
			else {
              console.log(error.message);
              //this.showAlert("Oops!", error.message);
            }
        });
      }
      this.setState({ commitRefreshing:false });
    }
  }

  render() {
    const { imageSets, commitingSets, loading } = this.state;
    const { loggedIn, loggedInUser } = this.props;
    if (!loggedIn) {
      return ( <Redirect from="*" to="/signin"/> );
    }
	return (
      <div>
		<Header 
          loggedIn = {loggedIn} 
          loggedInUser = {loggedInUser} 
          fullWidth
        />
        <div className="interactions">
          <div className="button-holder">
            <Button block variant="warning"
              onClick={this.handleShow}
            >
              Add Image Set
            </Button>
          </div>
        </div>
        { imageSets && imageSets.length!==0 ? (
          <div className="table">
            <ListOfImageSet
              list={imageSets}
              onDelete={this.onDelete}
              onUpdated={this.onUpdated}
            />
          </div>
                ) : (
          <div className="table-empty">
            { loading ? (
              <Spinner animation="border"
                       role="status" aria-hidden="true"/>
            ) : (
              <p>No <i><b>image sets</b></i> yet, you can add <i><b>image set</b></i> by using the button above, once you add an <i><b>image set</b></i> you will be able to add image(s) to <i><b>image set</b></i>
              </p>
            )}
          </div>
        )}
        { this.state.show ? (
          <ImageSetForm
            onClose={this.handleClose}
            title="New Image Set"
            actionName="Add"
            onSubmit={this.onAddImageSet}
          />
        ) : (
          <div/>
        )}
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
        { commitingSets && commitingSets.length!==0 ? (
          <Timer
              interval={Config.COMMITING_REFRESH_INTERVAL}
              onExpiry={this.onRefreshCommiting}
          />
        ) : (
          <div/>
        )}
      </div>
	);
  }
}

export default Dashboard;
