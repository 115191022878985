import React, { Component } from "react";
import PropTypes from 'prop-types';

import "./Typewriter.css";


export default class Typewriter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      displayText: ""
    };
    this.getRawText = this.getRawText.bind(this);
    this.type = this.type.bind(this);
    this.erase = this.erase.bind(this);
    this.startTyping = this.startTyping.bind(this);
  }

  componentDidMount() {
    this.startTyping();
  }

  componentDidUpdate(prevProps) {
    if (this.props.text !== prevProps.text) {
      this._timeout && clearTimeout(this._timeout);
      this.startTyping();
    }
  }

  componentWillUnmount() {
    this._timeout && clearTimeout(this._timeout);
  }

  startTyping() {
    this._timeout = setTimeout(() => {
      this.type();
    }, this.props.typingDelay);
  }

  getRawText() {
    const { text } = this.props;
    return typeof text === "string" ? [text] : [...text];
  }

  type() {
    let { index, displayText } = this.state;
    let text = this.getRawText()[index];
    if(text.length > displayText.length) {
      displayText = text.substr(0, displayText.length+1);
      this.setState({ displayText }, () => {
        this._timeout = setTimeout(() => {
          this.type();
        }, this.props.speed);
      });
    } else {
      this.props.onFinished();
      this._timeout = setTimeout(() => {
        this.erase();
      }, this.props.eraseDelay);
    }
  }

  erase() {
    let { displayText } = this.state;
    if (displayText.length !== 0) {
      displayText = displayText.substr(-displayText.length, (displayText.length-1));
      this.setState({ displayText }, () => {
        this._timeout = setTimeout(() => {
          this.erase();
        }, this.props.speed);
      });
    }
  }

  render() {
    const {
      staticText,
    } = this.props;
    const { displayText } = this.state;
    return (
      <span>
        {staticText ?
          <span className="demo-url-static">
            {staticText}
          </span> : null}
        <span className="demo-url-dynamic">{displayText}</span>
        <span className="blinking-cursor"><b>|</b></span>
      </span>
    );
  }
}

Typewriter.defaultProps = {
  speed: 30,
  eraseDelay: 5000,
  typingDelay: 100
};

Typewriter.propTypes = {
  speed: PropTypes.number.isRequired,
  typingDelay: PropTypes.number.isRequired,
  eraseDelay: PropTypes.number.isRequired,
  staticText: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  className: PropTypes.string,
  cursor: PropTypes.string,
  cursorClassName: PropTypes.string
};

