export const API_DOMAIN = (process.env.REACT_APP_IMAGEOPT_ENV === 'prod') ? 
                'https://jz7heljsaf.execute-api.ap-south-1.amazonaws.com/api' : 
                'https://kqv4pkhplc.execute-api.ap-south-1.amazonaws.com/api'

export const SITE_ROOT_RESET = "/reset";
export const SITE_ROOT_VERIFY = "/verify";

export const CONTENT_IMAGE_PREFIX = 'https://cdn.imageopt.com'

export const BRAND = 'imageOpt'
export const SERVICE = 'imageOpt.com'
export const EMAIL_TERMS = 'contact@imageopt.com'
export const EMAIL_SALES = 'contact@imageopt.com'

export const COMMITING_REFRESH_INTERVAL = '22000'

export const REACT_APP_GA_TRACKING_ID = 
	(process.env.REACT_APP_IMAGEOPT_ENV === 'prod') ? 
		'UA-155535339-1':''

export const REACT_APP_GA_DEBUG = 'false'
