import React, { Component } from 'react';

class Timer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timerId: -1
    }

    this.onExpiry = this.onExpiry.bind(this);
  }

  componentDidMount() {
    const { interval } = this.props;
   this.setState({
     timerId: setInterval(this.onExpiry, interval )
   });
  }

  componentWillUnmount() {
   clearInterval(this.state.timerId);
  }

  onExpiry() {
    const { onExpiry } = this.props;
    onExpiry();
  }

  render() {
    return (
      <div/>
    )
  }
}

export default Timer;
