import React, { PureComponent } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'


export default class PreviewImage extends PureComponent {

  getURLParams(width, height, crop) {
    var scale = 1;
    if (window.devicePixelRatio) {
      scale = window.devicePixelRatio;
    }

    const URLParams = '?w=' + width + '&h=' + height
      + '&x=' + scale
        +  ( crop ? '&crop' : '&fit' );
    return URLParams;
  }

  render() {
	const { imageSetURL, item, crop } = this.props;
    const URL = imageSetURL
                + this.getURLParams(item.width, item.height, crop)
	return (
    <div className="static-preview-item-div">
      <div className="static-preview-image-container"
           style={{width:item.width, height:item.height}}
      >
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip className="preview-tooltip">{URL}</Tooltip>}
         >
          <img alt="" className="static-preview-image"
             style={{width:item.width, height:item.height}}
               src={URL}
          />
        </OverlayTrigger>
      </div>
      { item.width >= 240 ? (
        <Row>
          <Col className="preview-info-width">
            Width: <b>{item.width}</b>
          </Col>
          <Col className="preview-info-ratio">
            <b>{item.info}</b>
          </Col>
          <Col className="preview-info-height">
            Height: <b>{item.height}</b>
          </Col>
        </Row>
      ) : ( 
        <Row>
          <Col className="preview-info-width">
            W:<b>{item.width}</b>
          </Col>
          <Col className="preview-info-ratio">
            <b>{item.info}</b>
          </Col>
          <Col className="preview-info-height">
            H:<b>{item.height}</b>
          </Col>
        </Row>
      )} 
    </div>
	);
  }
}

