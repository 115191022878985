import React, { Component } from "react";
import { Spinner, Button, Form, Jumbotron, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';

import ApiService from "./ApiService";

import HeaderSmall from './HeaderSmall';
import ModalAlert from './ModalAlert';
import "./Signin.css";

export default class Change extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current_password: "",
      new_password: "",
      confirm:"",
      name:"",
      inprocess:false,
      submitted:false,
      alertShow:false,
      alertTitle:"",
      alertDesc:""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);

  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }

  enableActionButton() {
    return this.state.current_password.length > 0 
      && this.state.new_password.length > 0
      && this.state.confirm.length > 0;
  }

  validateForm() {
    if (this.state.new_password !== this.state.confirm) {
      this.showAlert("Oops!", "Passwords don't match");
      return false;
    }
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.validateForm()) return;
	this.setState({inprocess:true});
    const userId = sessionStorage.getItem('loginUserId')
	ApiService.user.changePassword(userId, {
				current_password: this.state.current_password, 
				new_password: this.state.new_password,
		})
    	.then(response => {
			//console.log(response);
			//var status = response.data.success;
			this.setState({inprocess:false, submitted:true});
		})
		.catch((error) => {
			this.setState({inprocess:false});
            if (error.response) {
			  this.showAlert("Oops!", error.response.data.message);
            }
            else {
              this.showAlert("Oops!", error.message);
            }
			//console.log(error);
		})
  }

  render() {
    if (this.state.submitted) {
      return (
        <div>
          <HeaderSmall/>
          <div className="header-center-title">
            <span>imageOpt</span>
          </div>
          <Container>
            <div className="page">
              <Jumbotron>
                <h1>Changed!</h1>
                <p>
                  Your password is changed.</p>
                <p>
                  <Link to="/">
                    <Button variant="warning">Continue</Button>
                  </Link>
                </p>
              </Jumbotron>
            </div>
          </Container>
        </div>
      );
    }
    return (
      <div>
        <HeaderSmall/>
        <div className="header-center-title">
          <span>Change your password</span>
        </div>
        <div className="login">
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="current_password">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                autoFocus
                placeholder="Current Password"
                value={this.state.current_password}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group controlId="new_password">
              <Form.Label>New password</Form.Label>
              <Form.Control
                value={this.state.new_password}
                onChange={this.handleChange}
                type="password"
                placeholder="New password"
              />
            </Form.Group>
            <Form.Group controlId="confirm">
              <Form.Label>Confirm</Form.Label>
              <Form.Control
                value={this.state.confirm}
                onChange={this.handleChange}
                type="password"
                placeholder="Confirm"
              />
            </Form.Group>
 
            { !this.state.inprocess ? (
              <Button variant="warning"
                block
                disabled={!this.enableActionButton()}
                type="submit"
              >
                Change password
              </Button>
              ) : (
              <Button
                block
                variant="warning" 
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </Form>
        </div>
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
      </div>
    );
  }
}
