import React, { Component } from "react";
import { Spinner, Button, Jumbotron, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import * as qs from 'query-string';

import ApiService from "./ApiService";

import HeaderSmall from './HeaderSmall';
import ModalAlert from './ModalAlert';
import "./Signin.css";

export default class Verify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inprocess:true,
      verified:false,
      error:false,
      errorMessage:""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);

    const parsed = qs.parse(window.location.search);
	if (parsed["verify"] && parsed["key"]) {
      this.handleSubmit(parsed["verify"],parsed["key"]);
    }
	else {
      this.state.error = true;
    }

  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }


  handleSubmit(verify,key) {
    this.setState({inprocess:true});
    ApiService.user.verify({
            verify: verify, 
            key: key
        })
        .then(response => {
            //console.log(response);
            var status = response.data.success;
            this.setState({inprocess:false, verified:status});
        })
        .catch((error) => {
            //console.log(error);
            var errorMessage = "";
            if (error.response) {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = error.message;
            }
            this.setState({
              inprocess:false,
              error:true,
              errorMessage:errorMessage
            });
        });
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <HeaderSmall/>
          <div className="header-center-title">
            <span>imageOpt</span>
          </div>
          <Container>
            <div className="page">
              <Jumbotron>
                <h1>Oops!</h1>
                <p>
                  {this.state.errorMessage}
                </p>
                <p>
                  <Link to="/">
                    <Button variant="warning">Home</Button>
                  </Link>
                </p>
              </Jumbotron>
            </div>
          </Container>
        </div>
      );
    }
    return (
      <div>
        <HeaderSmall/>
        <div className="header-center-title">
          <span>imageOpt</span>
        </div>
        { this.state.verified ? (
          <Container>
            <div className="page">
              <Jumbotron>
                <h1>Welcome!</h1>
                <p>
                  Signup in now complete, you can now sign in and start using imageOpt.
                </p>
                <p>
                  <Link to="/signin">
                    <Button variant="warning">Sign in</Button>
                  </Link>
                </p>
              </Jumbotron>
            </div>
          </Container>
        ) : (
          <Container>
            <div className="page" style={{color:'#ddd',textAlign:'center'}}>
              <p>Verifying...</p>
              <Spinner
                 variant="warning"
                 as="span"
                 animation="border"
                 role="status"
                 aria-hidden="true"
              />
            </div>
          </Container>
        )}
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
      </div>
    );
  }
}
