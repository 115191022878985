import React, { PureComponent } from 'react';
import { Spinner, OverlayTrigger, Popover, Button, Container, Row, Col } from "react-bootstrap";
import { Redirect } from 'react-router-dom';

import ApiService from './ApiService';
import ModalAlert from './ModalAlert';
import ImageSetForm from './ImageSetForm';


import { ListOfImageItem } from './ImageItem';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import './App.css';

class ImageSet extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      deleting:false,
      loggedIn:true,
      show:false,
      modified:false,
      saving:false,
      alertShow:false,
      alertTitle:"",
      alertDesc:""
    };

    this.urlRef = React.createRef();

    this.onChange = this.onChange.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
    this.onUpdateImage = this.onUpdateImage.bind(this);
    this.onOpenImage = this.onOpenImage.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onPublish = this.onPublish.bind(this);
    this.onCommit = this.onCommit.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onUpdateImageSet = this.onUpdateImageSet.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);

  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }


  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
      modified: true
    });
  }

  handleCopy() {
    let urlElement = this.urlRef.current;
    window.getSelection().removeAllRanges();
    var range = document.createRange();
    range.setStartBefore(urlElement.firstChild);
    range.setEndAfter(urlElement.lastChild);
    window.getSelection().addRange(range);
    try {
        document.execCommand('copy');
    } catch (err) {
        console.log('Unable to copy');
    }
  }

  onUpdateImageSet(newImageSet) {
	const { item, onUpdated } = this.props;
    this.setState({ saving : true });
    return new Promise((resolve, reject) => {
      var data = {
        name: newImageSet.name,
        description: newImageSet.description,
        enable_lang: newImageSet.enable_lang,
      }
      if (newImageSet.enable_lang) {
        data.default_lang = newImageSet.default_lang;
	  }
      ApiService.imageSet.update( item._id, data )
      .then(response =>  {
        onUpdated(response.data.data);
        this.setState({ show:false, saving : false });
        resolve(true)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401
               || error.response.status === 403
               || error.response.status === 419) {
            reject("Not logged or Session Expired");
          }
          else {
            reject(error.response.data.message);
          }
        }
        else {
          reject(error.message);
        }
        this.setState({ saving : false });
      });
    })
  }

  onChange(event) {
    var signed;
    const { item, onUpdated } = this.props;
    this.setState({
        uploading: true,
    })
    const files = Array.from(event.target.files);

    ApiService.imageSet.imagePost(item._id)
    .then(response =>  {
      signed = response.data.data;
      const formData = new FormData();
      if (files.length > 0) {
        for(const field in signed.upload_fields) {
          formData.append(field, signed.upload_fields[field]);
        }
        formData.append("Content-Type",files[0].type);
        formData.append("file",files[0]);
                
        return ApiService.file.upload(signed.upload_url, formData);
      }
      throw new Error("Unable to get the file to upload");
    })
    .then(response => {
      return ApiService.imageSet.imageConfirm(item._id, {
        confirm_key: signed.confirm_key,
        originalname: files[0].name,
        mimetype: files[0].type
      })
    })
    .then(response => {
      onUpdated(response.data.data);
      this.setState({ uploading : false });
	})
    .catch(error => {
      if (error.response) {
        if (error.response.status === 401
             || error.response.status === 403
             || error.response.status === 419) {
          this.showAlert("Oops!", "Not logged or Session Expired");
          this.setState({ loggedIn: false, uploading:false });
        }
        else {
          this.showAlert("Oops!", error.response.data.message);
          this.setState({uploading: false})
        }
      }
      else {
        this.showAlert("Oops!", error.message);
        this.setState({uploading: false})
      }
    });
  }

  onDelete(event) {
    if (!this.state.deleting) {
      const { item, onDelete} = this.props;
	  this.setState({ deleting: true})
	  onDelete(item._id)
      .catch(error => {
        this.setState({deleting: false})
      });
    }
  }

  onPublish(event) {
    if (!this.state.saving) {
	  const { item, onUpdated } = this.props;
      this.setState({ saving : true });
      ApiService.imageSet.publish(item._id)
      .then(response =>  {
        onUpdated(response.data.data);
        this.setState({ saving : false });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401
               || error.response.status === 403
               || error.response.status === 419) {
            this.showAlert("Oops!", "Not logged or Session Expired");
          }
          else {
            this.showAlert("Oops!", error.response.data.message);
          }
        }
        else {
          this.showAlert("Oops!", error.message);
        }
        this.setState({ saving : false });
      });
    }
  }

  onCommit(event) {
    if (!this.state.saving) {
	  const { item, onUpdated } = this.props;
      this.setState({ saving : true });
      ApiService.imageSet.commit(item._id)
      .then(response =>  {
        onUpdated(response.data.data);
        this.setState({ saving : false });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401
               || error.response.status === 403
               || error.response.status === 419) {
            this.showAlert("Oops!", "Not logged or Session Expired");
          }
          else {
            this.showAlert("Oops!", error.response.data.message);
          }
        }
        else {
          this.showAlert("Oops!", error.message);
        }
        this.setState({ saving : false });
      });
    }
  }

  onPreview(event) {
	const { item } = this.props;
	const cdn = sessionStorage.getItem('dC');
	const url = '/preview/' + cdn + '/' + item._id +'/q';
    window.open(url, "_blank");
  }

  onDeleteImage(_id) {
    return new Promise((resolve, reject) => {
      const { item, onUpdated } = this.props;
      ApiService.imageSet.deleteImage(item._id, _id)
      .then(response =>  {
        onUpdated(response.data.data);
        resolve(true);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401
               || error.response.status === 403
               || error.response.status === 419) {
            this.showAlert("Oops!", "Not logged or Session Expired");
            this.setState({ loggedIn: false });
          }
          else {
            this.showAlert("Oops!", error.response.data.message);
          }
        }
        else {
          this.showAlert("Oops!", error.message);
        }
        reject(error);
      });
    });
  }

  onUpdateImage(_id, key, value) {
    return new Promise((resolve, reject) => {
	  const { item, onUpdated } = this.props;
      var data;

      if (key === "horizontal") 
        data = { horizontal_position: value };
	  else if (key === "vertical")
        data = { vertical_position: value };
      else if (key === "lang")
		data = { lang: value };

	  ApiService.imageSet.updateImage(item._id, _id, data)
      .then(response =>  {
        onUpdated(response.data.data);
        resolve(true);
	  })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401
               || error.response.status === 403
               || error.response.status === 419) {
            reject("Not logged or Session Expired");
          }
          else {
            reject(error.response.data.message);
          }
        }
        else {
          reject(error.message);
        }
      });
    });
  }

  onOpenImage(_id) {
	const { item } = this.props;
	ApiService.imageSet.getImage(item._id, _id)
	.then(response =>  {
		//console.log(response);
		window.open(response.data.data.s3Url, "_blank");
	});
  }

  render() {
	const { item } = this.props;
	const cdn = sessionStorage.getItem('dC');
	const url = cdn + '/' + item._id +'/q';
    if (!this.state.loggedIn) {
      return ( <Redirect from="*" to="/signin"/> );
    }
	return (
	<div key={item._id} className="table-row">
        <Container fluid>
		  <div className="table-row-title"> 
			Image URL:&nbsp;&nbsp;
            { ( item.published ) ? (
              <span style={{whiteSpace:"normal"}}>
                <span className="url-value" ref={this.urlRef}>
                  {url}&nbsp;
                </span>
                <Button className="button-copy-url"
                        onClick={this.handleCopy}
                        variant="outline-light"
                >
                  Copy
                </Button>
                { ( item.freshness.status === "fresh" ) ? (
                  <Button className="button-copy-url"
                          onClick={this.onPreview}
                          variant="outline-light"
                  >
                    Preview
                  </Button>
                ):(
                  <div/>
                )} 
              </span>
            ) : (
              <span>
                <span className="url-value">
                  URL will be assigned once imageSet is published.
                </span>
              </span>
            )}
		  </div>
        </Container>
		<div className="table-row-top">
          <Container fluid>
            <Row>
            <Col xs={11} md={11}>
            <Row>
              <Col sm={4}>
			    <div className="mt-1"> 
				  Name:<span className="text-value">{item.name}</span>
			    </div>
              </Col>
              <Col sm={8}>
			    <div className="mt-1"> 
				  Description:<span className="text-value">{item.description}</span>
			    </div>
              </Col>
            </Row>
            </Col>
            <Col xs={1} md={1}>
          <div className="button-delete-imageset">
            <OverlayTrigger
              trigger="click"
              placement="left"
              rootClose={true}
              overlay={
                <Popover>
                  <Popover.Title>
                     Delete imageSet?
                  </Popover.Title>
                  <Popover.Content>
                    imageSet along with all the images in it will be deleted. This can't be undone.
                    <Button block variant="warning" onClick={this.onDelete}>
                      Confirm
                    </Button>
                  </Popover.Content>
                </Popover>
              }
            >
              <i className="material-icons">delete_forever</i>
            </OverlayTrigger>
          </div>
          <div className="button-edit-imageset">
            <i className="material-icons"
              onClick={this.handleShow}>edit
            </i>
          </div>
          </Col>
          </Row>
          </Container>
		</div>
		<div className="table-row-bottom">
			<ListOfImageItem
		    	list={item.images}
				folder={item._id}
                enableLang={item.enable_lang}
				defaultLang={item.default_lang}
		      	onDelete={this.onDeleteImage}
		      	onUpdate={this.onUpdateImage}
				onOpen={this.onOpenImage}
		  	/>
			<div className="table-row-add-button-container">
				<label htmlFor={'file-new-'+item._id} className="table-row-add-button">
					{ this.state.uploading? (
						<div className="table-row-add-button-content">
			 				<Spinner animation="border"/>
							<br/><br/>
							<span>Uploading...</span>
						</div>
					) : (
						<div className="table-row-add-button-content">
							<span style={{fontSize:"36px"}}>+</span>
							<br/>
                            { (item.images.length === 0) ? (
							  <span>Add Image</span>
                            ) : (
							  <span style={{lineHeight:"1.5"}}>Add Image<br/> of differnt aspect ratio</span>
                            )}
						</div>
					)}
				</label>
				{ this.state.uploading? (
					<div/>
				) : (
					<input type="file" accept=".jpg,.jpeg,.png,.tiff,.webp,.gif,.svg" id={'file-new-'+item._id} onChange={this.onChange}/>
				)}
			</div>
            { ( !item.published || item.freshness.status !== "fresh" ) ? (
              <div className="table-row-action-buttons-container">
              { ( !item.published ) ?(
                <div>
                  <Button 
                    className="table-action-button"
                    variant="warning"
                    onClick={this.onPublish}
                    disabled={item.images.length===0}
                  >
                    Publish
                  </Button>
                  { item.images.length > 0 ? (
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      rootClose={true}
                      overlay={
                        <Popover>
                          <Popover.Title>
                            Delete imageSet?
                          </Popover.Title>
                          <Popover.Content>
                            All updates to this imageSet will be lost, this can't be undone.
                            <Button block
                              variant="warning"
                              onClick={this.onDelete}
                            >
                              Confirm
                            </Button>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Button
                        className="table-action-button"
                        variant="secondary"
                      >
                        Discard
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <Button 
                      className="table-action-button" 
                      variant="secondary"
                      onClick={this.onDelete}
                    >
                      Discard
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  { item.freshness.status === "progressing" ? (
                    <div class="table-commiting-container">
                      <Spinner animation="border" size="sm"
                        role="status" aria-hidden="true"/>
                      <span> Migrating changes ...</span>
                    </div>
                  ) : (
                    <Button 
                      className="table-action-button-large"
                      variant="warning"
                      onClick={this.onCommit}
                    >
                      Migrate Changes
                    </Button>
                  )}
                </div>
              )}
              </div>
            ) : (
              <div className="table-row-action-buttons-container">
                <Button 
                  className="table-action-button"
                  variant="warning"
                  onClick={this.onPreview}
                  disabled={item.images.length===0}
                >
                  Preview
                </Button>
              </div>
            )}
		</div>
        { this.state.show ? (
          <ImageSetForm
            onClose={this.handleClose}
            title="Image Set"
            actionName="Save"
            onSubmit={this.onUpdateImageSet}
            object={item}
          />
        ) : (
          <div/>
        )}
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
        { ( this.state.deleting || this.state.saving ) ? (
          <div className="table-overlay">
            <Spinner animation="border"/>
          </div>
        ) : ( <div></div>
        )}
	</div>
	);
  }
}

const ListOfImageSet = ({list, onDelete, onUpdated }) =>
	list.map( item => <ImageSet key={item._id} item={item} onDelete={onDelete} onUpdated={onUpdated}/>)

export {ImageSet, ListOfImageSet};
