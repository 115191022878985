import React, { Component } from "react";
import { Container } from "react-bootstrap";

import HeaderSmall from './HeaderSmall';
import * as Config from './Config'
import "./Contact.css";


export default class Contact extends Component {
  render() {
    const email = Config.EMAIL_TERMS
    return (
      <div>
        <HeaderSmall/>
        <div className="header-center-title">
          <span>Contact</span>
        </div>
        <Container className="contact">
          <p>Please feel free to contact us at the email address <strong><a href={"mailto:"+email}>{email}</a></strong>.</p>
         </Container>
      </div>
    );
  }
}
