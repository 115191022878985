import React, { Component } from "react";
import { Spinner, Button, Form, Jumbotron, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import * as qs from 'query-string';

import ApiService from "./ApiService";

import HeaderSmall from './HeaderSmall';
import ModalAlert from './ModalAlert';
import "./Signin.css";

export default class Reset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      confirm:"",
      inprocess:false,
      submitted:false,
      error:false,
      alertShow:false,
      alertTitle:"",
      alertDesc:"",
      errorMessage:""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);

    const parsed = qs.parse(window.location.search);
    if (!parsed['reset']) {
      this.state.error = true;
    }
  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }

  enableActionButton() {
    return this.state.email.length > 0 
      && this.state.password.length > 0
      && this.state.confirm.length > 0;
  }

  validateForm() {
    if (this.state.password !== this.state.confirm) {
      this.showAlert("Oops!", "Passwords don't match");
      return false;
    }
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit(event) {
    const parsed = qs.parse(window.location.search);
    event.preventDefault();
    if (!this.validateForm()) return;
	this.setState({inprocess:true});
	ApiService.user.resetPassword({
			email: this.state.email, 
			new_password: this.state.password,
            reset: parsed["reset"],
		})
    	.then(response => {
			//console.log(response);
			//var status = response.data.success;
			this.setState({inprocess:false, submitted:true});
		})
        .catch((error) => {
            //console.log(error);
            var errorMessage = "";
            if (error.response) {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = error.message;
            }
            this.setState({
              inprocess:false,
              error:true,
              errorMessage:errorMessage
            });
        });
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <HeaderSmall/>
          <div className="header-center-title">
            <span>imageOpt</span>
          </div>
          <Container>
            <div className="page">
              <Jumbotron>
                <h1>Oops!</h1>
                <p>
                  {this.state.errorMessage}
                </p>
                <p>
                  <Link to="/">
                    <Button variant="warning">Home</Button>
                  </Link>
                </p>
              </Jumbotron>
            </div>
          </Container>
        </div>
      );
    }
    return (
      <div>
        <HeaderSmall/>
        <div className="header-center-title">
          <span>Reset password</span>
        </div>
        { this.state.submitted ? (
        <Container>
          <div className="page">
            <Jumbotron>
              <h1>Done!</h1>
              <p>
                Your password is reset, you can now sign in and continue using imageOpt.</p>
              <p>
                <Link to="/signin">
                  <Button variant="warning">Sign in</Button>
                </Link>
              </p>
            </Jumbotron>
          </div>
        </Container>
        ) : (
        <div>
        <div className="login">
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                autoFocus
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
                placeholder="Password"
              />
            </Form.Group>
            <Form.Group controlId="confirm">
              <Form.Label>Confirm</Form.Label>
              <Form.Control
                value={this.state.confirm}
                onChange={this.handleChange}
                type="password"
                placeholder="Confirm"
              />
            </Form.Group>
 
            { !this.state.inprocess ? (
              <Button variant="warning"
                block
                disabled={!this.enableActionButton()}
                type="submit"
              >
                Reset password
              </Button>
              ) : (
              <Button
                block
                variant="warning" 
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </Form>
        </div>
        </div>
        )}
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
      </div>
    );
  }
}
