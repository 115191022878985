import React, { Component } from "react";
import { Container, Jumbotron, Card, CardDeck, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';

import ImageAnimation from './ImageAnimation';
import * as Config from './Config';

import Header from './Header';
import "./Home.css";

export default class Home extends Component {

  render() {
    const { loggedIn, loggedInUser } = this.props;

    return (
      <>
	    <Container>
          <Header
            loggedIn = {loggedIn}
            loggedInUser = {loggedInUser}
          />
        </Container>
		<div className="home-banner">
          <Container>
            <Jumbotron>
              <Row>
                <div className="col-flex-min-320">
                  <h1><b>Image CDN</b> <i>for <span className="extra-bold">faster</span> & <span className="extra-bold">responsive</span></i> <b>website</b>, <b>mobile app</b>.</h1>
                  <ul>
                    <li>No need to <span className="extra-bold">manage different size variants</span> of an <span className="extra-bold">image</span>.</li>
                    <li><span className="extra-bold">Dynamic image resizing</span> & <span className="extra-bold">optimization</span> for <i>individual devices</i>, from a nearby CDN location.</li>
                    <li>Use <b>different images</b> for different <span className="extra-bold">languages</span>, <span className="extra-bold">form factors</span> with just <b>ONE URL</b>.</li>
                    <li><span className="extra-bold">WebP</span> support <b>detection</b> & <b>conversion</b> <i>for smaller, faster response</i>.</li>
                  </ul>
                  <Link style={{paddingTop:"8px"}} className="btn btn-dark" to="/signup">
                    START FREE
                  </Link>
                </div>
                <div className="col-min-320">
                  <ImageAnimation/>
                </div>
              </Row>
            </Jumbotron>
          </Container>
          <Container>
            <CardDeck>
              <Card>
                <Card.Img variant="top" alt="images shown to be dynamically resized and delivered from image CDN to different type of devices" src={Config.CONTENT_IMAGE_PREFIX+'/6-1eocwl91/q?w=360&h=180'}/>
                <Card.Body>
                  <Card.Title>Swift & Lean CDN</Card.Title>
                  <Card.Text>
                    Request for image of size equal to the view size, which mostly is smaller than the original image and get it from our global image CDN, resulting in <b>less data transfer</b> from a <b>near location</b>.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" alt="different images shown mapped to one url" src={Config.CONTENT_IMAGE_PREFIX+'/dma7i9-m0i/q?w=360&h=180'}/>
                <Card.Body>
                  <Card.Title>Design Freedom</Card.Title>
                  <Card.Text>
                    Design different layouts for different form factors, use different images for different aspect ratios, since the url stays the same <b>no dependency</b> on <b>development teams</b>.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" alt="one url mapping to different images based on requesting device" src={Config.CONTENT_IMAGE_PREFIX+'/dt2p3-7s7o/q?w=360&h=180'}/>
                <Card.Body>
                  <Card.Title>One URL per visual</Card.Title>
                  <Card.Text>
                    No need to maintain multiple URLs for thumbnails/images of different sizes. Just upload <b>a high resolution image</b> and let imageOpt take care of serving <b>as many different size</b> images as needed.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck> 
            <br style={{lineHeight:"2"}}/>
            <CardDeck>
              <Card>
                <Card.Img variant="top" alt="images shown cropped in list view and shown to fit in detailed view" src={Config.CONTENT_IMAGE_PREFIX+'/p3-l0d051k/q?w=360&h=180'}/>
                <Card.Body>
                  <Card.Title>Crop or Fit</Card.Title>
                  <Card.Text>
                    Request for an image that should be <b>cropped to view size</b>, or for an image that <b>fits in the view</b>. <i>Ex: List view requires cropped image for consistent look and full screen view requires image to fit in the view for detailed look.</i>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" alt="images shown cropped from different sides in a list view" src={Config.CONTENT_IMAGE_PREFIX+'/g21ulya-ch/q?w=360&h=180'}/>
                <Card.Body>
                  <Card.Title>Crop Options</Card.Title>
                  <Card.Text>
                    Each image has important areas.  For each image that is uploaded we provide options to choose which <b>sides of image</b> to <b>retain while cropping</b>. This option depends on image content, the clients are agnostic to this.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" alt="animation showing image changing from car to suv on a laptop" src={Config.CONTENT_IMAGE_PREFIX+'/r4-lfx0toj/q?w=360&h=180'}/>
                <Card.Body>
                  <Card.Title>Freedom to Change</Card.Title>
                  <Card.Text>
                    imageOpt allows you to <b>change the image</b> on the fly, the <b>URL remains the same</b>, you just need to upload a new image. Time taken for the changes to reflect on the client depends on the client's cache policy.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck> 
          </Container>
        </div>
      </>
    );
  }
}
