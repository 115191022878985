import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import Typewriter from './Typewriter';
import * as Config from './Config';
import "./ImageAnimation.css";

const imageParams = [
    /* Changes here needs corresponding css changes */
	{ width:320, height:320, crop:true, fw:320, fh:320,
      info:"Large thumbnail square cropped" },
	{ width:214, height:160, crop:true, fw:214, fh:160,
      info:"Medium thumbnail landscape cropped" },
	{ width:80, height:80, crop:true, fw:80, fh:80,
      info:"Small thumbnail square cropped" },
	{ width:214, height:160, crop:false, fw:214, fh:143,
      info:"Medium landscape image fit inside" },
	{ width:320, height:320, crop:false, fw:320, fh:214,
      info:"Large landscape image fit inside" },
	{ width:270, height:320, crop:false, fw:214, fh:320,
      info:"ImageSet has two images 2nd is Portrait" },
	{ width:214, height:320, crop:false, fw:214, fh:320,
      info:"Portrait size returns different image content" },
	{ width:160, height:320, crop:true, fw:160, fh:320,
      info:"Portrait thumbnail cropped" },
];

export default class ImageAnimation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index:-1,
      imageUrlParams:this.constructUrl(0)
    };

    this.updateUrl = this.updateUrl.bind(this);
    this.updateIndex = this.updateIndex.bind(this);
  }

  componentDidMount() {
    var intervalId = setInterval(this.updateUrl, 7000);
    this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  updateUrl() {
    const nextIndex = (this.state.index+1) % imageParams.length;
    this.setState({ imageUrlParams: this.constructUrl(nextIndex) });
  }

  constructUrl(pIndex) {
    var scale = 1; 
      if (window.devicePixelRatio) {
        scale = window.devicePixelRatio;
      }
      const imageParam = imageParams[pIndex];
      return '?w=' + imageParam.width + '&h=' + imageParam.height
	    + '&x=' + scale
        +  ( imageParam.crop ? '&crop' : '&fit' );
  }

  updateIndex() {
     this.setState({ index: (this.state.index+1) % imageParams.length});
  }

  render() {
    var scale = 1; 
    if (window.devicePixelRatio) {
      scale = window.devicePixelRatio;
    }

    const staticUrl = 
      Config.CONTENT_IMAGE_PREFIX+'/2e0d6k-p25/q' 

    const imageParam = imageParams[this.state.index];
    var imageClass = 'demo-image-container-img'; 
    if (this.state.index >= 0) {
      imageClass = imageClass 
                   + ' demo-image-container-img-' + this.state.index;
    }
    return (
      <>
        <div className="demo-url">
          <Typewriter 
            staticText={staticUrl} 
            text={this.state.imageUrlParams} 
            onFinished={this.updateIndex}
          />
        </div> 
          { this.state.index >= 0 ? (
            <div className="demo-container">
              <div className="demo-image-container" 
                   style={{width:imageParam.width, height:imageParam.height}}>
                <div className={imageClass}
                     style={{width:imageParam.fw, height:imageParam.fh}}
                />
              </div>
            </div>
          ) : (
            <div className="demo-container"/>
          )}

          { this.state.index >= 0 ? (
            <Container className="demo-info">
              <Row>
                <Col className="demo-info-desc">{imageParam.info}</Col>
              </Row>
              <Row>
                <Col className="demo-info-width">
                  Width : <b>{imageParam.width}</b>
                </Col>
                <Col className="demo-info-scale">
                  DPR: <b>{scale}</b>
                </Col>
                <Col className="demo-info-height">
                  Height : <b>{imageParam.height}</b>
                </Col>
              </Row>
            </Container> 
          ) : (
            <Container className="demo-info">
              <Row>
                <Col className="demo-info-desc">&nbsp;</Col>
              </Row>
              <Row>
                <Col className="demo-info-width">Width : - px</Col>
                <Col className="demo-info-scale">Scale : </Col>
                <Col className="demo-info-height">Height : - px</Col>
              </Row>
            </Container>
          )}

      </>
    )
  }
}
