import React, { Component } from "react";
import { Spinner, Button, Form } from "react-bootstrap";
import { Redirect, Link } from 'react-router-dom';

import ApiService from './ApiService';
import HeaderSmall from './HeaderSmall';
import ModalAlert from './ModalAlert';
import "./Signin.css";

export default class Signin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      inprocess:false,
      loggedIn:false,
      alertShow:false,
      alertTitle:"",
      alertDesc:""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);

  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit(event) {
	const {onLoginStatusChange} = this.props
    event.preventDefault();
	this.setState({inprocess:true});
    
    ApiService.user.login( {
            email: this.state.email,
            password: this.state.password,
            token: true
        })
    	.then(response => {
			//console.log(response);
            var token = response.data.data.token;
			var userId = response.data.data.id;
			var deployment = response.data.data.img
			onLoginStatusChange(true, this.state.email, token, 
                                userId, deployment);
			this.setState({inprocess:false, loggedIn:true});
		})
		.catch((error) => {
			this.setState({inprocess:false});
			if (error.response) {
              this.showAlert("Oops!",error.response.data.message);
            }
            else {
              this.showAlert("Oops!",error.message);
            }
			//console.log(error);
		})
  }

  render() {
    if (this.state.loggedIn) {
      return ( <Redirect from="*" to="/dashboard"/> );
    }
    return (
      <div>
		<HeaderSmall/>
        <div className="header-center-title">
          <span>Sign in to imageOpt</span>
        </div>
        <div className="login">
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                placeholder="Enter email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
                placeholder="Password"
              />
            </Form.Group>
            { !this.state.inprocess ? (
              <Button variant="warning"
                block
                disabled={!this.validateForm()}
                type="submit"
              >
                Sign in
              </Button>
              ) : (
              <Button
                block
                variant="warning" 
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
            <div style={{marginTop:'10px',textAlign:'center',fontSize:'13px'}}>
              <Link to="/forgot">Forgot password?</Link>
            </div>
          </Form>
        </div>
        <div className="login-bottom">
          <span>New to imageOpt? </span>
          <Link to="/signup">Create an account</Link>
        </div>
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
      </div>
    );
  }
}
