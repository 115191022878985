import React, { Component } from 'react';
import { BrowserRouter, Redirect, Switch, Route, Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
import ScrollToTop from './ScrollToTop';
import Signin from './Signin';
import Signout from './Signout';
import Signup from './Signup';
import Verify from './Verify';
import Forgot from './Forgot';
import Reset from './Reset';
import Change from './Change';
import Dashboard from './Dashboard';
import Home from './Home';
import Documentation from './Documentation';
import Preview from './Preview';
import Faq from './Faq';
import Pricing from './Pricing';
import Terms from './Terms';
import Privacy from './Privacy';
import Contact from './Contact';
import NotFound from './NotFound';
import GAPageTracker from './GAPageTracker';

import './App.css';

class App extends Component {

  constructor(props) {
	super(props);

    this.state = {
	  loggedIn: JSON.parse(sessionStorage.getItem('loggedIn')),
	  loginUser: sessionStorage.getItem('loginUserEmail'),
    };

	this.onLoginStatusChange = this.onLoginStatusChange.bind(this);
  }

  onLoginStatusChange(status, email, token, userId, deployment) {
	if (status) {
		sessionStorage.setItem('loggedIn',true);
        sessionStorage.setItem('loginToken',token);
		sessionStorage.setItem('loginUserId',userId);
		sessionStorage.setItem('loginUserEmail',email);
		sessionStorage.setItem('dA',deployment.api);
		sessionStorage.setItem('dT',deployment.token);
		sessionStorage.setItem('dC',deployment.cdn);
    	this.setState({ loggedIn: status, loginUser: email });
	}
	else {
        sessionStorage.setItem('loggedIn',false);
        sessionStorage.setItem('loginToken','');
		sessionStorage.setItem('loginUserId','');
	    sessionStorage.setItem('loginUserEmail','');
		sessionStorage.setItem('dA','');
		sessionStorage.setItem('dT','');
		sessionStorage.setItem('dC','');
        this.setState({ loggedIn: false, loginUser: '' });
	}
  }

  render() {
    const { loggedIn, loginUser } = this.state;

    return (
      <BrowserRouter>
        { GAPageTracker.init() && <GAPageTracker.RouteTracker /> }
        <ScrollToTop>
          <Switch>
            <Route path="/signup/:plan(100|500|custom)?" exact component={Signup}/>
            <Route path="/verify" component={Verify}/>
            <Route path="/forgot" component={Forgot}/>
            <Route path="/reset" component={Reset}/>
            <Route path="/signout" render={(props) =>
              <Signout
                onLoginStatusChange = {this.onLoginStatusChange} />}
            />
 
            <Route path="/terms" component={Terms}/>
            <Route path="/privacy" component={Privacy}/>
            <Route path="/contact" component={Contact}/>
            { this.state.loggedIn ? (
              <Switch>
                <Route path="/change" component={Change}/>
                <Route path="/signin" render={()=> 
                  <Redirect to="/dashboard"/>}/>
                <Route exact path="/dashboard" render={(props) =>
                  <Dashboard
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser}
                    onLoginStatusChange = {this.onLoginStatusChange} />}
                />
                <Route exact path="/" render={(props) =>
                  <Home
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser} />}
                />
                <Route exact path="/documentation" render={(props) =>
                  <Documentation
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser} />}
                />
                <Route exact path="/faq" render={(props) =>
                  <Faq
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser} />}
                />
                <Route exact path="/pricing" render={(props) =>
                  <Pricing
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser} />}
                />
                <Route exact path="/preview/:url(https://?[a-z0-9]+.[a-z0-9]+.[a-z0-9]+/[a-z-0-9]+/q)" render={(props) =>
                  <Preview
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser}
                    imageSetURL={props.match.params.url} />}
                />
                <Route component={NotFound}/>
              </Switch>
            ) : (
              <Switch>
                <Route path="/change" render={()=> <Redirect to=""/>}/>
                <Route path="/dashboard" render={()=> <Redirect to=""/>}/>
                <Route exact path="/signin" render={(props) =>
		          <Signin onLoginStatusChange = {this.onLoginStatusChange} />}
                />
                <Route exact path="/" render={(props) =>
                  <Home
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser} />}
                />
                <Route exact path="/documentation" render={(props) =>
                  <Documentation
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser} />}
                />
                <Route exact path="/faq" render={(props) =>
                  <Faq
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser} />}
                />
                <Route exact path="/pricing" render={(props) =>
                  <Pricing
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser} />}
                />
                <Route exact path="/preview/:url(https://?[a-z0-9]+.[a-z0-9]+.[a-z0-9]+/[a-z-0-9]+/q)" render={(props) =>
                  <Preview
                    loggedIn={loggedIn}
                    loggedInUser ={loginUser}
                    imageSetURL={props.match.params.url} />}
                />
                <Route component={NotFound}/>
              </Switch>
            )}
          </Switch>
          <div className="footer">
            <Container>
              <Row>
                <Col md={6}>
                  <Link className="mt-4 navbar-brand" to="/" style={{color:'#fff'}} >
                    <img alt="imageOpt logo" src="/logo.svg" width="30" height="30"
                      className="d-inline-block"
                    />
                    {' imageOpt'}
                  </Link>
			      <div className="mt-1 mb-1">Copyright © 2019 imageOpt.com.<br/>All rights reserved.</div>
                  <div className="mt-2 mb-1">
                    <a className="mr-3" href="https://www.linkedin.com/company/imageopt" target="_blank" rel="noopener noreferrer">
                      <img alt="" src="/li_logo.png" height="30"/>
                    </a>
                    <a className="mr-3" href="https://www.instagram.com/imageopt/" target="_blank" rel="noopener noreferrer">
                      <img alt="" src="/in_logo.png" height="30"/>
                    </a>
                    <a className="mr-3" href="https://www.facebook.com/imageOpt" target="_blank" rel="noopener noreferrer">
                      <img alt="" src="/fb_logo.png" height="30"/>
                    </a>
                    <a className="mr-3" href="https://twitter.com/imageopt" target="_blank" rel="noopener noreferrer">
                      <img alt="" src="/tw_logo.png" height="30"/>
                    </a>
                    <a className="mr-3" href="https://github.com/imgopt" target="_blank" rel="noopener noreferrer">
                      <img alt="" src="/gh_logo.png" height="30"/>
                    </a>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="font-weight-bold text-uppercase footer-heading">imageOpt</div>
                  <ul>
			        <li className="footer-link"><Link to="/">Home</Link></li>
			        <li className="footer-link"><Link to="/documentation">Documentation</Link></li>
			        <li className="footer-link"><Link to="/pricing">Pricing</Link></li>
			        <li className="footer-link"><Link to="/faq">FAQ</Link></li>
                  </ul>
                </Col>
                <Col md={2}>
                  <div className="font-weight-bold text-uppercase footer-heading">Libraries</div>
                  <ul>
			        <li className="footer-link"><a href="https://github.com/imgopt/imageOptSwift" target="_blank" rel="noopener noreferrer">iOS</a></li>
			        <li className="footer-link"><a href="https://github.com/imgopt/imageOptAndroid" target="_blank" rel="noopener noreferrer">Android</a></li>
                  </ul>
                </Col>
                <Col md={2}>
                  <div className="font-weight-bold text-uppercase footer-heading">Legal</div>
                  <ul>
			        <li className="footer-link"><Link to="/privacy">Privacy</Link></li>
			        <li className="footer-link"><Link to="/terms">Terms</Link></li>
			        <li className="footer-link"><Link to="/contact">Contact</Link></li>
                  </ul>
                </Col>

             </Row>
            </Container>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
