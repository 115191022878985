import React, { Component } from "react";
import { Container, Card } from "react-bootstrap";
import { Link } from 'react-router-dom';

import Header from './Header';
import "./Documentation.css";
//import DociOS from './DociOS';
//import DocAndroid from './DocAndroid';

export default class Documentation extends Component {

  render() {
    const { loggedIn, loggedInUser } = this.props;

    return (
      <>
	    <Container>
          <Header
            loggedIn = {loggedIn}
            loggedInUser = {loggedInUser}
          />
        </Container>
        <div className="header-center-title">
          <span>Documentation</span>
        </div>
		<div className="doc-content">
          <Container>
            <Card>
              <Card.Header>
                UPLOAD YOUR FIRST IMAGE & GET AN URL
              </Card.Header>
              <Card.Body>
                <ul> 
                  <li>
                    <Link to="/signup">Sign up</Link> and <Link to="/signin">sign in</Link> to imageOpt
                  </li>
                  <li className="doc-sec">
                    Once signed in you will be onto the imageOpt landing page
                  </li>
                  <li>
                    Click on <span className="doc-hl">Add Image Set</span> to create your first Image Url, in the form that appear give a name and optionally some description to easily identify your image URL
                  </li>
                  <li className="doc-sec">
                    imageOpt will create an empty image set, on the top is the URL that will point to this image set, below that you will see the name and description
                  </li>
                  <li>
                    Now add an image to this url by clicking on <span className="doc-hl">Add Image</span>, select an image file from file system
                  </li>
                  <li className="doc-sec">
                    The uploaded image should appear to the left of the "Add Image" button
                  </li>
                  <li>
                    Now activate this URL by publishing it by clicking on <span className="doc-hl">Publish</span>, once imageSet is published the imageOpt URL will be revealed
                  </li>
                  <li className="doc-sec">
                    Copy and share the image url with backend/server team, so that they can add the url to the rest of the data/content, or directly use it in your website or mobile app.
                  </li>
 
                </ul>
				<br/>
                <p>Repeat the steps above to create more image URLs. One can also add more images of different aspect ratios to the same image set, this is useful when one single image won't look good in different aspect ratios. For example one might want to show different images in portrait and landscape orientation.</p>
              </Card.Body>
            </Card>
            <br/>
            <br/>
            <Card>
              <Card.Header>
                URL PARAMETERS
              </Card.Header>
              <Card.Body>
                <p>imageOpt URL</p>
                <p>
                  <span className="doc-sec">https://dbcaihe8155jo.cloudfront.net/9no-cq/q</span><span className="doc-hl">?w=100&h=120&x=1.5&l=en&c</span>
                </p>
                <p>The URL has two parts, base URL and query parameters</p>
                <ul>
                  <li className="doc-sec">
                    The base URL is the fixed part which you get when you create image set, shown in grey color above
                  </li>
                  <li className="doc-hl">
                    Query parameters are for the clients to request specific size, language and fit, shown in orange color abore
                  </li>
                </ul>
                <br/>
                There are six query parameters:<br/>
                <div style={{paddingLeft:"15px",lineHeight:"1.5"}}>
                  <span className="doc-hl">w </span>
                  : specifies the desired image width in pixel
                  <br/>
                  <span className="doc-hl">h </span>
                  : specifies the desired image height in pixel
                  <br/>
                  <span className="doc-hl">x </span>
                  : specifies the scale factor, image height and width will be multiplied with this to get the final pixel values, default is 1
                  <br/>
                  <span className="doc-hl">l </span>
                  : specifies the desired language of the image, when present only images matching the language will be considered for the response, however if no images of desired language after present then images of default language will be considered for the response. Language is specified by two character ISO 639-1 Code.
                  <br/>
                   <span className="doc-hl">f </span>
                  : specifies if the image has to fit inside the size(width x height), returned image will have either height or width same as request and the other dimension will be less than requested
                  <br/>
                   <span className="doc-hl">c </span>
                  : specifies if the image has to be cropped to fit inside the size(width x height), returned image will have same dimension as requested
                  <br/>
                  <br/>
                </div>
                <p>Note: Only one of "f" or "c" can be requested, in case none of them are requested, "f" is default</p>
              </Card.Body>
            </Card>
            <br/>
            <br/>
            <Card>
              <Card.Header>
                SWIFT / SWIFTUI / OBJECTIVE-C LIBRARY
              </Card.Header>
              <Card.Body>
                <p>imageOptClient library makes it simple to use imageOpt with iOS or tvOS app</p>
                <ul> 
                  <li>
                    Follow instructions at <a href="https://github.com/imgopt/imageOptSwift" target="_blank" rel="noopener noreferrer">GitHub</a> to setup imageOptClient library
                  </li>
                  <li>
                    Refer documentation and examples for Swift, SwiftUI and Objective-C at <a href="https://github.com/imgopt/imageOptSwift#documentation" target="_blank" rel="noopener noreferrer">GitHub</a>
                  </li>
				</ul>
                <br/>
				<p> 
                    GitHub link : <a href="https://github.com/imgopt/imageOptSwift" target="_blank" rel="noopener noreferrer">https://github.com/imgopt/imageOptSwift</a>
				</p>
              </Card.Body>
            </Card>
            <br/>
            <br/>
            <Card>
              <Card.Header>
                ANDROID LIBRARY
              </Card.Header>
              <Card.Body>
                <p>imageOptClient library makes it simple to use imageOpt with Android app</p>
                <ul> 
                  <li>
                    Follow instructions at <a href="https://github.com/imgopt/imageOptAndroid" target="_blank" rel="noopener noreferrer">GitHub</a> to setup imageOptClient library
                  </li>
                  <li>
                    Refer documentation and examples at <a href="https://github.com/imgopt/imageOptAndroid#documentation" target="_blank" rel="noopener noreferrer">GitHub</a>
                  </li>
				</ul>
                <br/>
				<p> 
                    GitHub link : <a href="https://github.com/imgopt/imageOptAndroid" target="_blank" rel="noopener noreferrer">https://github.com/imgopt/imageOptAndroid</a>
				</p>
              </Card.Body>
            </Card>
            <br/>
            <br/>

            <Card>
              <Card.Header>
                FETCH & SHOW IMAGE ON WEBSITE
              </Card.Header>
              <Card.Body>
                <p>We will first discuss how to use imageOpt with inline responsive images and later also show how to use imageOpt with css and media query</p>
                <p><b>Inline responsive images</b></p>
                <div className="doc-content-indended">
                <p>We will be using <span className="doc-hl">srcset</span> attirbute, it helps us make sure the client browser asks for correct image size. Requesting a correct image size is very important for quick and effecient loading.</p>
                <p>Browser support for <b>srcset</b> <a href="https://caniuse.com/#feat=srcset">https://caniuse.com/#feat=srcset</a><br/>
                Note: Even if old browsers does not support srcset, it always downloads a fall back images - So things are backward compatible.</p>
                <p>If you are new to responsive images, you can learn about responsive images @ <a href="https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images">Responsive Images | MDN</a>
                </p>
                <p>Now that's out of the way, lets look into some examples</p>
                </div>
                <p><u>Srcset & Display density descriptors</u></p>
                <div className="doc-content-indended">
                <p>Syntax for display density</p>
                <pre>{"\
<img src=\"https://domain/id\" alt=\"cat\"\n\
     srcset=\"https://domain/id/q?w=250, \n\
             https://domain/id/q?w=250&x=2 2x, \n\
             https://domain/id/q?w=250&x=1.5 1.5x\">\n\
                "}</pre>
                <p>The display density values— the 2x, 1.5x — are referred to as display density descriptors or device pixel ratio or dpr or device independent pixels or dip or dp. If a display density descriptor isn’t provided, it is assumed to be 1x.</p>
                Lets consider the scenario for above example:
                <ol>
                  <li>Client is iPad Mini - Density is 1 ⇒ id/q?w=250 used</li>
                  <li>Sony smartwatch 3 - Density is 1.5 (hdpi) ⇒ id/q?w=250&x=1.5 used</li>
                  <li>iPhone 6 Density is 2.0 ⇒ id/q?w=250&x=2 used</li>
                  <li>IE 11 - No srcset support ⇒ https://domain/id used</li>
                </ol>
                <p>Display density descriptors are best for fixed width images and insufficient for flexible images</p>
                </div>
                <p><u>Srcset & Width descriptors</u></p>
                <div className="doc-content-indended">
                <p>Syntax for width descriptor</p>
                <pre>{"\
<img src=\"https://domain/id\" alt=\"cat\"\n\
     srcset=\"https://domain/id/q?w=250 250w, \n\
             https://domain/id/q?w=500 500w,\n\
             https://domain/id/q?w=1080 1080w\"\n\
     sizes=\"100vw\">\n\
                "}</pre>
                <p>The difference is that instead of having 1x, 2x, and other values representing the density, we now list the width of the image source such as 320w, 480w, etc.</p>
                <p>Sizes only makes sense if you’re using the width descriptors. If you’re using the display density descriptors, you don’t need the sizes attribute. The browser won’t know what to do with it.</p>
                <p>We’re telling the browser what size the image will be in relation to the size of the viewport.</p>
                <p>Each vw unit represents 1% of the viewport width, which is a fancy way of saying that 100vw is 100% of the viewport width and 33vw is 33% of the viewport width.</p>
                <p>Sizes can be a media condition. A media condition is similar to a media query, but not as full featured. We can do something like</p>
                <p>sizes="(max-width: 480px) 100vw, (max-width: 900px) 33vw, 254px"</p>
                <p>Most commonly, your media condition is going to be a something like ‘(max-width: 480px)’ or maybe ‘(min-width: 480px)’.</p>
                <p>The length doesn’t have to be expressed as a viewport width unit. It can be any length including absolute and relative length. You can even use CSS calc() to do things like auto-calculate margins dynamically.</p>
                <p>Read More @
                <a href="https://cloudfour.com/thinks/responsive-images-101-part-5-sizes/">Srcset & Width descriptors from cloudfour responsive-images-101
css-viewport-units-quick-start</a>
                </p>
                <p>Note:<br/>
                vw units have wide support in current browsers <a href="https://caniuse.com/#feat=viewport-units">https://caniuse.com/#feat=viewport-units</a>
                </p>
                <p>Srcset and sizes provide all of the functionality you need for the resolution switching use case. They give the browser just enough information to allow it to make smart decisions.</p>
				</div>
                <hr style={{background:"black"}}/>
                <p><b>CSS images</b></p>
                <div className="doc-content-indended">
                <p>Everything we’ve talked about so far has been for inline responsive images. Because we already had media queries in CSS, inline responsive images were the biggest challenge and most of the focus has been on them</p>
                <p>When it comes to CSS images, we could always use media queries</p>
                <p>A quick example of css image selection using media queries</p>
                <pre>{"\
@media all and (min-width: 1800px) {\n\
    #image_id {\n\
        background-image:url('id/q?w=1800');\n\
    }\n\
}\n\
@media all and (min-width: 1024px) {\n\
    #image_id {\n\
        background-image:url('id/q?w=1024');\n\
    }\n\
}\n\
                "}</pre>
				</div>
                <hr style={{background:"black"}}/>
                <p><b>More options</b></p>
                <div className="doc-content-indended">
                <p>Picture tag is also a good option specially for art direction in responsive design -> <a href="https://www.w3schools.com/tags/tag_picture.asp">https://www.w3schools.com/tags/tag_picture.asp</a>
                </p>
                <p>Browser support for <b>picture</b> <a href="https://caniuse.com/#search=picture">https://caniuse.com/#search=picture</a><br/>
                Note: Backward compatible + polyfill available @<a href="https://scottjehl.github.io/picturefill">https://scottjehl.github.io/picturefill</a>
                </p>
                <p>There is image-set() for resolution switching but not much supported -> <a href="https://cloudfour.com/thinks/responsive-images-101-part-8-css-images/">Read about it from cloudfour responsive-images-101</a></p>
                <p><u>More Resources</u>:<br/>
                <a href="https://dev.opera.com/articles/responsive-images/">Use Cases and Documented Code Snippets from dev.opera</a><br/>
                <a href="https://ericportis.com/posts/2014/srcset-sizes/">Ericportis.com Srcset and sizes</a><br/>
                <a href="https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images">MDN - Responsive images</a><br/>
                <a href="https://developers.google.com/web/fundamentals/design-and-ux/responsive/images">Developer.google Images</a><br/>
                <a href="https://timkadlec.com/2012/04/media-query-asset-downloading-results/">Media Query & Asset Downloading Results</a>
                </p>
				</div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}
