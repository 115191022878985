import React, { PureComponent } from 'react';
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap";
import { Link } from 'react-router-dom';

class HeaderNav extends PureComponent {

  render() {
    const { loggedIn, loggedInUser } = this.props;
    return (
      <>
        <Link className="navbar-brand" to="/">
          <img alt="imageOpt logo" src="/logo.svg" width="30" height="30"
            className="d-inline-block"
          />
          {' imageOpt'}
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        { loggedIn ? 
        (
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Link className="nav-link" to="/dashboard">DASHOARD</Link>
              <Link className="nav-link" to="/documentation">DOCUMENTATION</Link>
              <Link className="nav-link" to="/pricing">PRICING</Link>
              <Link className="nav-link" to="/faq">FAQ</Link>
            </Nav>
            <Nav>
              <NavDropdown alignRight title={loggedInUser} id="collasible-nav-dropdown">
                <Link className="dropdown-item" to="/change">Change Password</Link>
                <NavDropdown.Divider />
                <Link className="dropdown-item" to="/signout">Signout</Link>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        ) : (
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Link className="nav-link" to="/">HOME</Link>
              <Link className="nav-link" to="/documentation">DOCUMENTATION</Link>
              <Link className="nav-link" to="/pricing">PRICING</Link>
              <Link className="nav-link" to="/faq">FAQ</Link>
            </Nav>
            <Nav>
              <Link className="nav-link" to="/signin">Sign in</Link>
              <Link className="nav-link" to="/signup">Sign Up</Link>
            </Nav>
          </Navbar.Collapse>
        )}
      </>
    );
  }
}

export default class Header extends PureComponent {

	render() {
      const { loggedIn, loggedInUser, fullWidth } = this.props;
      return (
          <div className="header">
            <Navbar bg="dark" expand="md" variant="dark" fixed="top">
              { fullWidth ? 
              (
                <HeaderNav
                  loggedIn = {loggedIn}
                  loggedInUser = {loggedInUser}/>
              ):(
                <Container>
                  <HeaderNav
                    loggedIn = {loggedIn}
                    loggedInUser = {loggedInUser}/>
                </Container>
              )}            
            </Navbar>
          </div>
	  );
    }
}

