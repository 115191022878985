import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";

class ModalAlert extends Component {

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const {onClose} = this.props;
    onClose();
  }

  render() {
    const { title, desc, show } = this.props;
    return (
      <Modal show={show} onHide={this.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{desc}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalAlert;
