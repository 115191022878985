import React, { Component } from "react";
import { Container, Spinner, Row, Col, ButtonGroup, Button } from "react-bootstrap";

import Header from './Header';
import ModalAlert from './ModalAlert';
import PreviewImage from './PreviewImage';

import './Preview.css';

const INIT_WIDTH = 320;
const INIT_HEIGHT = 320;
const INIT_CROPFIT = 'crop';
const MAX_WIDTH = 1200;
const MAX_HEIGHT = 1200;

const imageParams = [
    [
      { width:400, height:225, info:"16:9" },
      { width:300, height:225, info:"4:3" },
      { width:225, height:225, info:"1:1" }
    ],
    [
      { width:400, height:300, info:"4:3" },
      { width:300, height:300, info:"1:1" },
      { width:225, height:300, info:"3:4" }
    ],
    [
      { width:400, height:400, info:"1:1" },
      { width:300, height:400, info:"3:4" },
      { width:225, height:400, info:"9:16" }
    ]
/*
    [
      { width:480, height:270, info:"16:9" },
      { width:203, height:270, info:"3:4" },
      { width:180, height:270, info:"2:3" }
    ],
    [
      { width:432, height:270, info:"16:10" },
      { width:270, height:270, info:"1:1" },
      { width:169, height:270, info:"10:16" }
    ],
    [
      { width:405, height:270, info:"3:2" },
      { width:360, height:270, info:"4:3" },
      { width:152, height:270, info:"9:16" }
    ]
    [
      { width:427, height:240, info:"16:9" },
      { width:384, height:240, info:"16:10" },
      { width:360, height:240, info:"3:2" },
      { width:320, height:240, info:"4:3" },
      { width:240, height:240, info:"1:1" },
      { width:180, height:240, info:"3:4" },
      { width:160, height:240, info:"2:3" },
      { width:150, height:240, info:"10:16" },
      { width:135, height:240, info:"9:16" },
    ]
*/
];

export default class Preview extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      width:INIT_WIDTH,
      height:INIT_HEIGHT,
      cropfit:INIT_CROPFIT,
      tW:INIT_WIDTH,
      tH:INIT_HEIGHT,
      tCF:INIT_CROPFIT,
      loadedURL:'',
      loadingError:false
    };

    this.onWidthChange = this.onWidthChange.bind(this);
    this.onHeightChange = this.onHeightChange.bind(this);
    this.onCropfitChange = this.onCropfitChange.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.onReload = this.onReload.bind(this);
    this.getURLParams = this.getURLParams.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }

  onWidthChange(event) {
    this.setState({
      tW : event.target.value
    })
  }

  onHeightChange(event) {
    this.setState({
      tH : event.target.value
    })
  }

  onCropfitChange(event) {
    this.setState({
      tCF : event.target.value
    })
  }

  onImageLoaded() {
    const { imageSetURL } = this.props;
    const { width , height, cropfit } = this.state;
    const imageFetchURL = imageSetURL 
                          + this.getURLParams(width, height, cropfit);
    this.setState({
      loadingError:false,
      loadedURL:imageFetchURL
    })
  }

  onImageError() {
    this.showAlert("Image loading failed!", "Please check imageSet URL, network connection and try again.");
    this.setState({
      loadingError:true
    })
  }

  onReload() {
    const { tW, tH, tCF } = this.state;
    this.setState({
      loadingError:false,
      width:tW,
      height:tH,
      cropfit:tCF,
    })
  }

  getURLParams(width, height, cropfit) {
    var scale = 1;
    if (window.devicePixelRatio) {
      scale = window.devicePixelRatio;
    }

    const URLParams = '?w=' + width + '&h=' + height
      + '&x=' + scale
        +  ( cropfit === 'crop' ? '&crop' : '&fit' );
    return URLParams;
  }


  render() {
    const { loggedIn, loggedInUser, imageSetURL } = this.props;
    const { width , height, cropfit, tW, tH, tCF, loadedURL, loadingError} = this.state;
    const containerWidth = parseInt(width) + 26;
    const containerHeight = parseInt(height) + 26;
    const URLParams = this.getURLParams(tW, tH, tCF);
    const imageFetchURL = imageSetURL + this.getURLParams(width, height, cropfit);
    const imageLoading = imageFetchURL !== loadedURL;

    const previewImageClass = imageLoading ? "preview-image-hidden" : "preview-image";

    return (
      <>
	    <Container>
          <Header
            loggedIn = {loggedIn}
            loggedInUser = {loggedInUser}
          />
        </Container>
        <div className="header-center-title">
          <span>Preview</span>
        </div>
		<div className="preview-content">
          <Container>
            <Row className="preview-params-row">
            <Col md={6}>
              <label>Width</label>
              <input type="range" variant='warning' min="10" step="10" max={MAX_WIDTH} value={tW} onChange={this.onWidthChange}/>
            </Col>
            <Col md={6}>
              <label>Height</label>
              <input type="range" variant='warning' min="10" step="10" max={MAX_HEIGHT} value={tH} onChange={this.onHeightChange}/>
            </Col>
            </Row>
            <Row className="preview-crop-fit-row">
              <ButtonGroup
                className="preview-crop-fit"
                toggle='true'
                onClick={this.onCropfitChange}
              >
                <Button
                  name='cropfit'
                  variant='secondary'
                  size='sm'
                  className={ tCF === 'fit' ? 'btn-selected':'' }
                  value={'fit'}
                >
                  Fit
                </Button>
                <Button
                  name='cropfit'
                  variant='secondary'
                  size='sm'
                  className={ tCF === 'crop' ? 'btn-selected':'' }
                  value={'crop'}
                >
                  Crop
                </Button>
              </ButtonGroup>
            </Row>
            <Row>
              <Col className="url-view-final">
                <div className="url-container-final">
                  <span>URL: </span>
                  <span className="url-value-final">{imageSetURL}</span>
                  <span className="url-params-final">{URLParams}</span>
                </div>
                <div className="preview-button-holder">
                { (imageLoading & !loadingError) ? (
                  <Button block style={{float:"right"}} variant="warning">
                    <Spinner animation="border" size="sm"
                             role="status" aria-hidden="true"/>
                  </Button>
                ) : (
                  <Button block style={{float:"right"}} variant="warning"
                    onClick={this.onReload}
                  >
                    Reload
                  </Button>
                )}
                </div>
             </Col>
            </Row>
          </Container>
        </div>
        <div className="preview-container"
             style={{width:containerWidth+'px', height:containerHeight+'px'}}
        >
          <div className="preview-image-container"
               style={{width:width+'px', height:height+'px'}}
          >
            <img alt="preview" className={previewImageClass} 
                 src={imageFetchURL}
                 onLoad={this.onImageLoaded}
                 onError={this.onImageError}
                 style={{width:width+'px', height:height+'px'}}
            />
          </div>
        </div>
        <Container className="static-preview-container">
          <div className="preview-divider"/>
          <div className="header-center-subtitle">
            <span>Fit inside various size</span>
          </div>
          {imageParams.map((valueR, indexR) => {
            return(
              <Row key={'f'+indexR} className="static-preview-row">
                {valueR.map((valueC, indexC) => {
                  return (
                    <PreviewImage key={'f'+indexR+'.'+indexC} 
                                  imageSetURL={imageSetURL}
                                  item={valueC}
                                  crop={false}
                    />
                  );
                })}
              </Row>
            );
          })}
          <div className="preview-divider"/>
          <div className="header-center-subtitle">
            <span>Cropped to various size</span>
          </div>
          {imageParams.map((valueR, indexR) => {
            return(
              <Row key={'c'+indexR} className="static-preview-row">
                {valueR.map((valueC, indexC) => {
                  return (
                    <PreviewImage key={'c'+indexR+'.'+indexC} 
                                  imageSetURL={imageSetURL}
                                  item={valueC}
                                  crop={true}
                    />
                  );
                })}
              </Row>
            );
          })}
        </Container>
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
      </>
    );
  }
}
