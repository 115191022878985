import React, { Component } from 'react';
import { Form, Button, Modal, Spinner } from 'react-bootstrap';
import ModalAlert from './ModalAlert';
import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import { LANGS } from './Langs'

export default class ImageLangForm extends Component {

  _autofocusInput: ?HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      object: props.object,
      modified: false,
      submitting: false,
      alertShow:false,
      alertTitle:"",
      alertDesc:"",
      selected: LANGS.filter( obj => {
                  return obj.id === props.object.lang;
                })
    }

    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  componentDidMount(prevProps, prevState) {
    this._autofocusInput.focus();
  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }

  onHandleSubmit() {
    const {onSubmit} = this.props;
    this.setState({submitting: true});
    onSubmit(this.state.object)
    .then(() => {
      this.setState({submitting: false});
    })
    .catch(error => {
      this.setState({submitting: false})
      this.showAlert("Opps", error );
    });
  }

  handleLanguageChange(selected) {
    if (selected.length > 0) {
      this.setState(prevState => ({
        object: {
          ...prevState.object,
          lang: selected[0].id
        },
        modified: true,
        selected: selected
      }));
    }
    else {
      this.setState({
        modified: true,
        selected : selected
      });
    }
  }

  handleChange = event => {
    const id = event.target.id;
    var value;
    if (event.target.type === 'checkbox')
      value = event.target.checked ? true : false
    else 
      value = event.target.value;
    this.setState(prevState => ({
      object: {
        ...prevState.object,
        [id]: value
      },
      modified: true
    }));
  }

  render() {
    const { onClose } = this.props;
    return (
      <>
        <Modal show={true} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>Language Selection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="lang">
              <Form.Label>Language</Form.Label>
              <Typeahead
                autofocus="true"
                labelKey={option => `${option.id} - ${option.name}`}
                selected={this.state.selected}
                onChange={this.handleLanguageChange}
                options={LANGS}
                placeholder="Select a language..."
				selectHintOnEnter={true}
                ref={c=> (this._autofocusInput = c) }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={this.onHandleSubmit}
              disabled={!this.state.modified}
            >
              Save
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </Modal.Footer>
          { ( this.state.submitting || this.state.alertShow ) ? (
            <div className="table-overlay">
            { this.state.submitting ? (
              <Spinner animation="border"/>
            ) : (
              <div/>
            )}
            </div>
          ) : ( 
            <div/>
          )}
        </Modal>
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
      </>
    )
  }
}
