import React, { Component } from "react";
import { Spinner, Button, Form, Jumbotron, Container, OverlayTrigger, Popover
 } from "react-bootstrap";
import { Redirect, Link } from 'react-router-dom';

import ApiService from './ApiService';
import HeaderSmall from './HeaderSmall';
import * as Config from './Config'
import ModalAlert from './ModalAlert';
import "./Signin.css";

export default class Signup extends Component {
  constructor(props) {
    super(props);
    const iPlan = this.getPricePlan(props.match.params.plan);
     
    this.state = {
      email: "",
      password: "",
      confirm:"",
      name:"",
      region:"US East (N. Virginia)",
      plan:iPlan,
      inprocess:false,
      loggedIn:false,
      submitted:false,
      alertShow:false,
      alertTitle:"",
      alertDesc:""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);

  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }

  enableActionButton() {
    return this.state.email.length > 0 
      && this.state.password.length > 0
      && this.state.confirm.length > 0;
  }

  validateForm() {
    if (this.state.password !== this.state.confirm) {
      this.showAlert("Oops!", "Passwords don't match");
      return false;
    }
    return true;
  }

  getPriceSlab(plan) {
    if(plan === "Pay as you go 100") return "slab_1";
    else if(plan === "Pay as you go 500") return "slab_2";
    else if(plan === "Pay as you go Custom") return "slab_3";
    else return "slab_0";
  }

  getPricePlan(slab) {
    if(slab === "100") return "Pay as you go 100";
    if(slab === "500") return "Pay as you go 500";
    else if(slab === "custom") return "Pay as you go Custom";
    else return "Forever Free";
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.validateForm()) return;
	this.setState({inprocess:true});
 
	ApiService.user.signup({
			name: this.state.name, 
			email: this.state.email, 
			password: this.state.password,
			region: this.state.region,
			price_slab: this.getPriceSlab(this.state.plan),
            company: "company",
            phone: "123456",
            site_root: Config.SITE_ROOT_VERIFY
		})
    	.then(response => {
			//console.log(response);
			var status = response.data.success;
			this.setState({inprocess:false, submitted:status});
		})
		.catch((error) => {
			this.setState({inprocess:false});
            if (error.response) {
              this.showAlert("Oops!", error.response.data.message);
            }
            else {
              this.showAlert("Oops!", error.message);
            }
			//console.log(error);
		})
  }

  render() {
    if (this.state.loggedIn) {
      return ( <Redirect from="*" to="/"/> );
    }
    return (
      <div>
        <HeaderSmall/>
        <div className="header-center-title">
          <span>Sign up for imageOpt</span>
        </div>
        { this.state.submitted ? (
        <Container>
          <div className="page">
            <Jumbotron>
              <h1>Thank you!</h1>
              <p>
                We are glad you joined imageOpt, you will receive an email from us, please click the verification link in the email to confirm that this email belongs to you.
              </p>
              <p>
                <Link to="/signin">
                  <Button variant="warning">Sign in</Button>
                </Link>
              </p>
            </Jumbotron>
          </div>
        </Container>
        ) : (
        <div>
        <div className="login">
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
                placeholder="Password"
              />
            </Form.Group>
            <Form.Group controlId="confirm">
              <Form.Label>Confirm</Form.Label>
              <Form.Control
                value={this.state.confirm}
                onChange={this.handleChange}
                type="password"
                placeholder="Confirm"
              />
            </Form.Group>

            <Form.Group controlId="region">
              <Form.Label>Region</Form.Label>
              <OverlayTrigger
                trigger={['click', 'hover', 'focus']}
                placement="left"
                rootClose={true}
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Title as="h3">Base infrastructure region</Popover.Title>
                    <Popover.Content>
                      Your imageOpt CDN/Cloud is always global, however we need a base location for certian infrastructure stack components.
                    </Popover.Content>
                  </Popover>
                }
              >
                <i className="material-icons" 
                  style={{fontSize:"18px",verticalAlign:"middle",float:"right"}}>
                  help_outline
                </i>
              </OverlayTrigger>
              <Form.Control as="select"
                value={this.state.region}
                onChange={this.handleChange}
              >
                <option>US East (N. Virginia)</option>
                <option>US West (N. California)</option>
                <option>EU (Frankfurt)</option>
                <option>EU (London)</option>
                <option>Asia Pacific (Mumbai)</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="plan">
              <Form.Label>
                Plan
              </Form.Label>
              <OverlayTrigger
                trigger={['click', 'hover', 'focus']}
                placement="left"
                rootClose={true}
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Title as="h3">Initial Plan</Popover.Title>
                    <Popover.Content>
                      Select your initial plan, if you are confused just select the <strong>Pay as you go 100</strong> plan, you can change your plan any time. Pricing page lists all the plans in detail.
                    </Popover.Content>
                  </Popover>
                }
              >
                <i className="material-icons" 
                  style={{fontSize:"18px",verticalAlign:"middle",float:"right"}}>
                  help_outline
                </i>
              </OverlayTrigger>
              <Form.Control as="select"
                value={this.state.plan}
                onChange={this.handleChange}
              >
                <option>Forever Free</option>
                <option>Pay as you go 100</option>
                <option>Pay as you go 500</option>
                <option>Pay as you go Custom</option>
              </Form.Control>
            </Form.Group>
            <div>
              <span>By clicking "Create an account" below, you agree to our </span>
              <Link to="/terms">Terms of Service </Link>
              <span>and </span>
              <Link to="/privacy">Privacy Policy </Link>
            </div>
            <br/>
            { !this.state.inprocess ? (
              <Button variant="warning"
                block
                disabled={!this.enableActionButton()}
                type="submit"
              >
                Create an account
              </Button>
              ) : (
              <Button
                block
                variant="warning" 
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </Form>
        </div>
        <div className="login-bottom">
          <span>Have an account? </span>
          <Link to="/signin">Sign in</Link>
        </div>
        </div>
        )}
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
      </div>
    );
  }
}
