import React, { Component } from 'react';
import { Form, Button, Modal, Spinner } from 'react-bootstrap';
import ModalAlert from './ModalAlert';
import { Typeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import { LANGS } from './Langs'

export default class ImageSetForm extends Component {

  _autofocusInput: ?HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      object: props.object,
      modified: false,
      submitting: false,
      alertShow:false,
      alertTitle:"",
      alertDesc:"",
      selected: LANGS.filter( obj => {
                  return obj.id === props.object.default_lang;
                })
    }

    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  componentDidMount(prevProps, prevState) {
    this._autofocusInput.focus();
  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }

  onHandleSubmit() {
    const {onSubmit} = this.props;
    this.setState({submitting: true});
    onSubmit(this.state.object)
    .then(() => {
      this.setState({submitting: false});
    })
    .catch(error => {
      this.setState({submitting: false})
      this.showAlert("Opps", error );
    });
  }

  handleLanguageChange(selected) {
    if (selected.length > 0) {
      this.setState(prevState => ({
        object: {
          ...prevState.object,
          default_lang: selected[0].id
        },
        modified: true,
        selected: selected
      }));
    }
    else {
      this.setState({
        modified: true,
        selected : selected
      });
    }
  }

  handleChange = event => {
    const id = event.target.id;
    var value;
    if (event.target.type === 'checkbox')
      value = event.target.checked ? true : false
    else 
      value = event.target.value;
    this.setState(prevState => ({
      object: {
        ...prevState.object,
        [id]: value
      },
      modified: true
    }));
  }

  render() {
    const { title, actionName, onClose } = this.props;
    return (
      <>
        <Modal show={true} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                placeholder="Give a name to imageSet"
                value={this.state.object.name}
                onChange={this.handleChange}
                ref={c=> (this._autofocusInput = c) }
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Describe your imageSet"
                value={this.state.object.description}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group controlId="enable_lang">
              <Form.Check 
                type="checkbox" 
                label="Enable Localization"
                checked={this.state.object.enable_lang}
                onChange={this.handleChange}
                style={{lineHeight:'1.5', fontSize:'1rem'}}
              />
            </Form.Group>
            <Form.Group controlId="default_lang">
              <Form.Label>Default Language</Form.Label>
              <Typeahead
                labelKey={option => `${option.id} - ${option.name}`}
                selected={this.state.selected}
                onChange={this.handleLanguageChange}
                options={LANGS}
                placeholder="Select a language..."
                disabled={!this.state.object.enable_lang}
				selectHintOnEnter={true}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={this.onHandleSubmit}
              disabled={!this.state.modified}
            >
              {actionName}
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </Modal.Footer>
          { ( this.state.submitting || this.state.alertShow ) ? (
            <div className="table-overlay">
            { this.state.submitting ? (
              <Spinner animation="border"/>
            ) : (
              <div/>
            )}
            </div>
          ) : ( 
            <div/>
          )}
        </Modal>
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
      </>
    )
  }
}

ImageSetForm.defaultProps = {
  object: { 
            name: '', 
            description: '', 
            enable_lang: false, 
            default_lang: '' 
          },
  actionName: 'Save'
}

ImageSetForm.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
  object: PropTypes.string.isRequired,
};
