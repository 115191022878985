import React, { Component } from "react";
import { Spinner, Button, Jumbotron, Container } from "react-bootstrap";
import { Redirect, Link } from 'react-router-dom';

import ApiService from './ApiService';
import HeaderSmall from './HeaderSmall';
import ModalAlert from './ModalAlert';
import "./Signin.css";

export default class Signout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finished:false,
      error:false,
      alertShow:false,
      alertTitle:"",
      alertDesc:""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);

    this.handleSubmit();

  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }

  handleSubmit() {
    const { onLoginStatusChange } = this.props;
	ApiService.user.logout()
    	.then(response => {
			//console.log(response);
			onLoginStatusChange(false);
			this.setState({finished:true});
		})
		.catch((error) => {
            if (error.response) {
              if (error.response.status === 401
                   || error.response.status === 403
                   || error.response.status === 419) {
			    onLoginStatusChange(false);
			    this.setState({finished:true});
              }
              else {
                this.showAlert("Oops!", error.response.data.message);
            	this.setState({error:true});
              }
            }
            else {
              this.showAlert("Oops!", error.message);
              this.setState({error:true});
            }
		})
  }

  render() {
    if (this.state.error) {
      return ( <Redirect to="/"/> );
    }
    return (
      <div>
        <HeaderSmall/>
        <div className="header-center-title">
          <span>imageOpt</span>
        </div>
        { this.state.finished ? (
          <Container>
            <div className="page">
              <Jumbotron>
                <h1>See Ya!</h1>
                <p>
                  You have successfully signed out. Hope you will be back soon!
                </p>
                <p>
                  <Link to="/signin">
                    <Button variant="warning">Sign in</Button>
                  </Link>
                </p>
              </Jumbotron>
            </div>
          </Container>
        ) : (
          <Container>
            <div className="page" style={{color:'#ddd',textAlign:'center'}}>
              <p>Signing out...</p>
              <Spinner
                 variant="warning"
                 as="span"
                 animation="border"
                 role="status"
                 aria-hidden="true"
              />
            </div>
          </Container>
        )}
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
      </div>
    );
  }
}
