import React, {Component} from 'react';
import { Link } from 'react-router-dom';
export default class HeaderSmall extends Component {
	render() {
		return (
			<div className="header-center">
            	<Link to="/">
            		<img alt="imageOpt logo" src="/logo.svg" width="50" height="50"
              			className="d-inline-block"
            		/>
            	</Link>
          	</div>
        );
	}
}
