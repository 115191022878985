import React, { Component } from "react";
import { Container, Card } from "react-bootstrap";
import { Link } from 'react-router-dom';

import Header from './Header';
import "./Documentation.css";
//import DociOS from './DociOS';
//import DocAndroid from './DocAndroid';

export default class Faq extends Component {

  render() {
    const { loggedIn, loggedInUser } = this.props;

    return (
      <>
	    <Container>
          <Header
            loggedIn = {loggedIn}
            loggedInUser = {loggedInUser}
          />
        </Container>
        <div className="header-center-title">
          <span>Frequently asked questions</span>
        </div>
		<div className="doc-content">
          <Container>
            <Card>
              <Card.Header>
                What is the region we select while signing up?
              </Card.Header>
              <Card.Body>
                <p>
                  This is for backend processing of your imageOpt set up - Permanent Storage for your original images, some compute resources etc. This does not matter for your clients, they will get served from global image CDN, irrespective of the region you selected for backend processing.
                </p>
              </Card.Body>
            </Card>
            <br/>
            <br/>
            <Card>
              <Card.Header>
                What is imageSet?
              </Card.Header>
              <Card.Body>
                <p>
                  imageSet is a set of images representing one visual, to give a perfect visual experience, one can show, different representations of the same images based on available display space
                </p>
                <p>
                  An imageSet.
                  <ul>
                    <li>
                      Has one URL.
                    </li>
                    <li className="doc-sec">
                      Has multiple images of different aspect ratios.
                    </li>
                    <li>
                      Each image has a preference to specify which part to retain while cropping.
                    </li>
                  </ul>
                </p>
              </Card.Body>
            </Card>
            <br/>
            <br/>
            <Card>
              <Card.Header>
                What is the difference between fit and crop?
              </Card.Header>
              <Card.Body>
                <p>
                When the aspect ratio(ratio of width to height) of requested size and image size does not match, there are two mostly used strategies.
                </p>
                <p>
                  <ol>
                    <li>
                      <b>Fit the image inside the requested rectangle.<br/></b>
                      In this case either one of height or width, of the returned image, will be the same as requested and other dimension will be smaller than requested.
                    </li>
                    <li>
                    <b>Fit the image outside the requested rectangle</b> and cut out the portion of the image that is outside.<br/>
                    In this case both width and height of the returned image will be same as requested, however the image will be cropped based on crop retain flag.
                    </li>
                  </ol>
                </p>
              </Card.Body>
            </Card>
            <br/>
            <br/>
            <Card>
              <Card.Header>
                What are the crop retain flags?
              </Card.Header>
              <Card.Body>
                <p>
                  <b>Each image has important areas</b>. For each image that is uploaded we provide options to choose which sides of the image to retain while cropping. This option depends on image content, the clients are agnostic to this.<br/><br/>
For each image in the imageSet you can set the horizontal and vertical crop retain flags.<br/><br/>
<b>Horizontal Crop Retain Flag</b> can be set to:<br/>
<b>Left</b>: when the image is cropped horizontally then the left side will be retained and the cropping will happen from the right side. <br/>
<b>Center</b>: when the image is cropped horizontally then the center will be retained and the cropping will happen equally both left & right side.<br/>
<b>Right</b>: when the image is cropped horizontally then the right side will be retained and the cropping will happen from the left side.<br/>
<br/>
<b>Vertical Crop Retain Flag</b> can be set to:<br/>
<b>Top</b>: when the image is cropped vertically then the top side will be retained and the cropping will happen from the bottom side.<br/>
<b>Center</b>: when the image is cropped vertically then the center will be retained and the cropping will happen equally both top & bottom side.<br/>
<b>Bottom</b>: when the image is cropped vertically then the bottom side will be retained and the cropping will happen from the top side.<br/>
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
            <Card>
              <Card.Header>
                What is publish?
              </Card.Header>
              <Card.Body>
                <p>
                  imageSet is kept in a draft state by default while it is being created and is not ready. Once all the images are added to the image set and crop retain flags set, it needs to be published. Once published URL for the imageSet will be shown and then it can be used. 
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
            <Card>
              <Card.Header>
                What is migrate?
              </Card.Header>
              <Card.Body>
                <p>
                  Any changes made to image set after publish, needs to be migrated across CDN and old cache in CDN needs to be cleaned. If there are more than one changes to image set we recommend to make all the changes first and then migrate.<br/><br/>
Note: We set the http headers such that the client will cache the image for one day and needs to revalidate post that. If the client follows standard cache control it might take upto one day for the changes to reflect on the client. If the client does not follow standard cache control then the behaviour will be undefined.
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
 
            <Card>
              <Card.Header>
                What are the image url parameters?
              </Card.Header>
              <Card.Body>
                <p>imageOpt URL</p>
                <p>
                  <span className="doc-sec">https://dbcaihe8155jo.cloudfront.net/9no-cq/q</span><span className="doc-hl">?w=100&h=120&x=1.5&l=en&c</span>
                </p>
                <p>The URL has two parts, base URL and query parameters</p>
                <ul>
                  <li className="doc-sec">
                    The base URL is the fixed part which you get when you create image set, shown in grey color above
                  </li>
                  <li className="doc-hl">
                    Query parameters are for the clients to request specific size, language and fit, shown in orange color abore
                  </li>
                </ul>
                <br/>
                There are six query parameters:<br/>
                <div style={{paddingLeft:"15px",lineHeight:"1.5"}}>
                  <span className="doc-hl">w </span>
                  : specifies the desired image width in pixel
                  <br/>
                  <span className="doc-hl">h </span>
                  : specifies the desired image height in pixel
                  <br/>
                  <span className="doc-hl">x </span>
                  : specifies the scale factor, image height and width will be multiplied with this to get the final pixel values, default is 1
                  <br/>
                  <span className="doc-hl">l </span>
                  : specifies the desired language of the image, when present only images matching the language will be considered for the response, however if no images of desired language after present then images of default language will be considered for the response. Language is specified by two character ISO 639-1 Code.
                  <br/>
                   <span className="doc-hl">f </span>
                  : specifies if the image has to fit inside the size(width x height), returned image will have either height or width same as request and the other dimension will be less than requested
                  <br/>
                   <span className="doc-hl">c </span>
                  : specifies if the image has to be cropped to fit inside the size(width x height), returned image will have same dimension as requested
                  <br/>
                  <br/>
                </div>
                <p>Note: Only one of "f" or "c" can be requested, in case none of them are requested, "f" is default</p>
              </Card.Body>
            </Card>
            <br/>
            <br/>
            <Card>
              <Card.Header>
                How do I use imageOpt in my iOS app?
              </Card.Header>
              <Card.Body>
                <p>You can use imageOpt Swift library, it makes it effortless to use imageOpt with iOS or tvOS app</p>
                <ul> 
                  <li>
                    Follow instructions at <a href="https://github.com/imgopt/imageOptSwift" target="_blank" rel="noopener noreferrer">GitHub</a> to setup imageOptClient library
                  </li>
                  <li>
                    Refer documentation and examples for Swift, SwiftUI and Objective-C at <a href="https://github.com/imgopt/imageOptSwift#documentation" target="_blank" rel="noopener noreferrer">GitHub</a>
                  </li>
				</ul>
                <br/>
				<p> 
                    GitHub link : <a href="https://github.com/imgopt/imageOptSwift" target="_blank" rel="noopener noreferrer">https://github.com/imgopt/imageOptSwift</a>
				</p>
              </Card.Body>
            </Card>
            <br/>
            <br/>
            <Card>
              <Card.Header>
                How do I use imageOpt in my Android app?
              </Card.Header>
              <Card.Body>
                <p>You can use imageOpt Java library, it makes it effortless to use imageOpt with Android app</p>
                <ul> 
                  <li>
                    Follow instructions at <a href="https://github.com/imgopt/imageOptAndroid" target="_blank" rel="noopener noreferrer">GitHub</a> to setup imageOptClient library
                  </li>
                  <li>
                    Refer documentation and examples at <a href="https://github.com/imgopt/imageOptAndroid#documentation" target="_blank" rel="noopener noreferrer">GitHub</a>
                  </li>
				</ul>
                <br/>
				<p> 
                    GitHub link : <a href="https://github.com/imgopt/imageOptAndroid" target="_blank" rel="noopener noreferrer">https://github.com/imgopt/imageOptAndroid</a>
				</p>
              </Card.Body>
            </Card>
            <br/>
            <br/>
            <Card>
              <Card.Header>
                How do I use imageOpt in my web app?
              </Card.Header>
              <Card.Body>
                <p>
                  You can use imageOpt with inline responsive images or with css and media query, refer to <Link to="/documentation">documentation</Link> for details.
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
            <Card>
              <Card.Header>
                How imageOpt image CDN processes imageSet URL?
              </Card.Header>
              <Card.Body>
                <p>
                  When a client, Web/Mobile app, requests for imageSet along with size and crop/fit preference.</p>
                <p>imageOpt image CDN in summary will<br/> 
                  <ul>
                    <li>Pick the image with nearest aspect ratio.</li>
                    <li>Scale if needed.</li>
                    <li>If cropping is requested, crop according to, crop preference for that image.</li>
                    <li>Send the processed image.</li>
                    <li>Cache the processed image in the CDN for future faster response.</li>
                  </ul>
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
            <Card>
              <Card.Header>
                What are the best practices to create an imageSet?
              </Card.Header>
              <Card.Body>
                <p>An ideal imageSet will have 
                  <ul>
                    <li>Images of all possible aspect ratios, that a client would request.</li>
                    <li>All images should be of highest resolution.</li>
                    <li>No two images should have the same aspect ratio.</li>
                    <li>For each image select the horizontal and vertical crop retain flag so as to make sure the area of importance in the image is retained while cropping.</li>
                  </ul>
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
            <Card>
              <Card.Header>
                How is the image selected from imageSet?
              </Card.Header>
              <Card.Body>
                <p>
                  First we check if the request is for landscape, portrait or square image.<br/><br/>
If the imageSet has image(s) of the requested orientation, from the images of that orientation, an image with the aspect ratio nearest to the requested aspect ratio, is picked.<br/><br/>
If the imageSet does not have any image of that orientation an image with the aspect ratio nearest to the request aspect ratio is picked.<br/><br/>
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
            <Card>
              <Card.Header>
                Can I change all the images in the imageSet?
              </Card.Header>
              <Card.Body>
                <p>
                  Yes, you can change all the images in the imageSet, however at any point in time atleast one image is required in the imageSet, so we suggest first add new images and then delete old ones, after making the changes to imageSet, click on "Migrate Changes" to initiate change migration, once changes are migrated across image CDN locations, the same URL will start returning newly added images.
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
            <Card>
              <Card.Header>
                What is the purpose of static URL?
              </Card.Header>
              <Card.Body>
                <p>
                  Each imageSet has a static URL, in other words the URL of imageSet does never change, however all the image in the imageSet can be changed. This enables you to change the images without changing your website code, application code and/or backend.
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
            <Card>
              <Card.Header>
                Is it OK to call the raw imageSet url?
              </Card.Header>
              <Card.Body>
                <p>
                  Not recommended, the behaviour is undefined.
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
            <Card>
              <Card.Header>
                What is the localization feature?
              </Card.Header>
              <Card.Body>
                <p>
                  Localization feature of imageOpt image CDN is useful when there is a need to show different images based on users language preference, for example when there is text overlay on the image or when a different local image would convey the visual message to the viewer in a more localized way.
                </p>
              </Card.Body>
            </Card>
            <br/><br/>
            <Card>
              <Card.Header>
                How does the localization feature work?
              </Card.Header>
              <Card.Body>
                <p>
                  Localization feature can be enabled on per imageSet basis, this feature can be enabled while creating the imageSet or later by editing the imageSet. When this feature is enabled a default language also needs to be set, this language is like your base/fallback language.<br/><br/>
                  For each image of the imageSet a language can be specified. If no language is specified for the image, language defaults to imageSet's default language.<br/><br/>
                  When an image is requested using imageSet URL, a language query parameter 'l' or 'lang' can be used to request image of a particular language. imageOpt's iOS and Android libraries provides feature to automatically add language parameter.<br/><br/>
                  When processing request with language parameter, only image with matching language will be considered and the image that matches best to the requested parameters will be returned. In case no image of the request lanuage is avaiable, one suited best from the default language will be returned.
                </p>
              </Card.Body>
            </Card>
 
 
 
          </Container>
        </div>
      </>
    );
  }
}
