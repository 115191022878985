import React, { Component } from "react";
import { Spinner, OverlayTrigger, Popover, Button, ButtonGroup } from "react-bootstrap";
import ImageItemForm from './ImageItemForm';
import ModalAlert from './ModalAlert';
import { LANGS } from './Langs';


class ImageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgress:false,
      alertShow:false,
      alertTitle:"",
      alertDesc:"",
      selected: LANGS.filter( obj => {
                  return obj.id === props.item.lang;
                })
    };

    this.onDelete = this.onDelete.bind(this);
    this.onFlagChange = this.onFlagChange.bind(this);
    this.onImageItemChange = this.onImageItemChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.item.lang !== prevProps.item.lang) {
      this.setState( {
	    selected: LANGS.filter( obj => {
                    return obj.id === this.props.item.lang;
                  })
      });
    }
  }

  showAlert(title,desc) {
    this.setState({
      alertShow:true,
      alertTitle:title,
      alertDesc:desc
    })
  }

  onCloseAlert() {
    this.setState({
      alertShow:false
    })
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  onFlagChange(event) {
    if (!this.state.inProgress) {
      const { item, onUpdate } = this.props;
      if ((event.target.name === "horizontal"
           && item.horizontal_position === event.target.value )
         || (event.target.name === "vertical"
           && item.vertical_position === event.target.value ))
      {
        return;
      }

      this.setState({inProgress: true})
      onUpdate(item._id, event.target.name, event.target.value)
      .then(() => {
        this.setState({inProgress:false});
      })
      .catch(error => {
		this.showAlert("Oops!", error);
        this.setState({inProgress: false});
      });
    }
  }

  onImageItemChange(newImageItem) {
	return new Promise((resolve,reject) => {
      const { item, onUpdate } = this.props;
      this.setState({inProgress: true})
      onUpdate(item._id, "lang", newImageItem.lang)
        .then(() => {
          this.setState({show:false, inProgress:false});
          resolve(true);
        })
        .catch(error => {
          this.setState({inProgress: false});
          reject(error);
        });
    });
  }

  onDelete(event) {
    if (!this.state.inProgress) {
      const { item, onDelete} = this.props;
      this.setState({inProgress: true})
      onDelete(item._id)
      .catch(error => {
        this.setState({inProgress: false})
      });
    }
  }

  render() {
    const { item, folder, onOpen, enableLang, defaultLang } = this.props;
    var horizontal = "centre";
    var vertical = "centre";
    if (item.horizontal_position) {
        horizontal = item.horizontal_position;
    }

    if (item.vertical_position) {
        vertical= item.vertical_position;
    }
 
    const cdn = sessionStorage.getItem('dC');
    return (
      <div className="table-row-image-container">
        <div className="table-row-image-row">
          <div className="table-row-image-content">
            <img
              alt="Loading..."
              className="table-row-image"
              onClick={()=> onOpen(item._id)}
              src={cdn
                + '/' + folder +'/q?_id='+item._id+'&h=200&f'}
            />
          </div>
          <div className="table-row-right-container">
            <div className="div-vertical">
              <div className="div-vertical-content">
                <div>Crop Retain</div>
                <ButtonGroup
                  toggle='true'
                  onClick={this.onFlagChange}
                >
                  <Button
                    name="vertical"
                    variant='secondary'
                    className={ vertical === 'bottom' ? 'btn-selected':'' }
                    value={'bottom'}
                  >
                    Bottom
                  </Button>
                  <Button
                    name="vertical"
                    variant='secondary'
                    className={ vertical === 'centre' ? 'btn-selected':'' }
                    value={'centre'}
                  >
                    Center
                  </Button>
                  <Button
                    name="vertical"
                    variant='secondary'
                    className={ vertical === 'top' ? 'btn-selected':'' }
                    value={'top'}
                  >
                    Top
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="button-delete">
            <OverlayTrigger
              trigger="click"
              placement="left"
              rootClose={true}
              overlay={
                <Popover>
                  <Popover.Title> 
                    Delete image?
                  </Popover.Title>
                  <Popover.Content>
                    This image will be deleted, this can't be undone.
                    <Button 
                      block 
                      variant="warning" 
                      onClick={this.onDelete}
                    >
                      Confirm
                    </Button>
                  </Popover.Content>
                </Popover>
              }
            >
              <i className="material-icons">delete_forever</i>
            </OverlayTrigger>
          </div>
          <div className="table-row-bottom-container">
            <div>Crop Retain</div>
            <ButtonGroup
              toggle='true'
              onClick={this.onFlagChange}
            >
              <Button
                name='horizontal'
                variant='secondary'
                className={ horizontal === 'left' ? 'btn-selected':'' }
                value={'left'}
              >
                Left
              </Button>
              <Button
                name='horizontal'
                variant='secondary'
                className={ horizontal === 'centre' ? 'btn-selected':'' }
                value={'centre'}
              >
                Center
              </Button>
              <Button
                name="horizontal"
                variant='secondary'
                className={ horizontal === 'right' ? 'btn-selected':'' }
                value={'right'}
              >
                Right
              </Button>
            </ButtonGroup>
          </div>
          { enableLang ? (
            <div className="table-row-lang-container">
              <span className="table-row-lang-label">Language: </span>
              <span className="table-row-lang-value"
                    onClick={this.handleShow}
              >
                { this.state.selected.length > 0 ? (
					<>{ this.state.selected[0].id + ' - ' 
                        + this.state.selected[0].name }</>
                ) : (
					<>Default({defaultLang})</>
                )}
			    <i className="material-icons"
				  style={{fontSize:"16px", margin:"2px 0 0 5px"}}
                  >edit
                </i>
              </span>
		    </div>
        ):(
		  <div/>
        )}
        </div>
        { this.state.inProgress? (
          <div className="table-overlay">
            <Spinner animation="border"/>
          </div>
        ) : ( <div></div>
        )}
		{ this.state.show ? (
          <ImageItemForm
            onClose={this.handleClose}
            onSubmit={this.onImageItemChange}
            object={item}
          />
        ) : (
          <div/>
        )}
        <ModalAlert
          onClose={this.onCloseAlert}
          show={this.state.alertShow}
          title={this.state.alertTitle}
          desc={this.state.alertDesc}
        />
      </div>
    );
  }
}

const ListOfImageItem = ({list, folder, onDelete, onUpdate, onOpen, enableLang, defaultLang }) =>
    list.map( item => <ImageItem key={item._id} item={item} folder={folder} onDelete={onDelete} onUpdate={onUpdate} onOpen={onOpen} enableLang={enableLang} defaultLang={defaultLang}/>)

export { ImageItem, ListOfImageItem };
