import axios from 'axios';
import * as Config from './Config';

const version = '/v1'

export default {
  user: {

    login(payload) {
      const url = Config.API_DOMAIN + version;
      return axios.post(url+'/login', payload);
    },

    signup(payload) {
      const url = Config.API_DOMAIN + version;
      return axios.post(url+'/users', payload);
    },

    verify(payload) {
      const url = Config.API_DOMAIN + version;
      return axios.post(url+'/verify/email', payload);
    },

    forgotPassword(payload) {
      const url = Config.API_DOMAIN + version;
      return axios.post(url+'/password/forgot', payload);
    },

    resetPassword(payload) {
      const url = Config.API_DOMAIN + version;
      return axios.post(url+'/password/reset', payload);
    },


    // The following apis needs the auth token

    logout() {
      const url = Config.API_DOMAIN + version;
      const token = sessionStorage.getItem('loginToken');
      return axios.post(url+'/logout', {}, {
           headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    changePassword(userId,payload) {
      const url = Config.API_DOMAIN + version;
      const token = sessionStorage.getItem('loginToken');
      return axios.put(url+'/users/'+userId+'/update-password', payload, {
           headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

  },

  imageSet: {

    fetch() {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.get(api+'/sets', {
            headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    get(id) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.get(api+'/sets/'+id, {
            headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    getCommitStatus(id) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.get(api+'/sets/'+id+'?commits=true', {
            headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    create(payload) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.post(api+'/sets', payload, {
           headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    update(id, payload) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.put(api+'/sets/'+id, payload, {
              headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    publish(id ) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.put(api+'/sets/'+id+'/publish', {}, {
              headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    commit(id ) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.put(api+'/sets/'+id+'/commit', {}, {
              headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    delete(id) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.delete(api+'/sets/'+id, {
             headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },


    getImage(imageSetId, imageId) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.get(api+'/sets/'+imageSetId+'/image/'+imageId, {
            headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    updateImage(imageSetId, imageId, payload) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.put(api+'/sets/'+imageSetId+'/image/'+imageId, 
          payload, {
            headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    deleteImage(imageSetId, imageId) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.delete(api+'/sets/'+imageSetId+'/image/'+imageId, {
           headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    imagePost(id) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.get(api+'/sets/'+id+'/image-post', {
          headers: {
              'Authorization' : 'Bearer '+token
          }
      });
    },

    imageConfirm(id, payload ) {
      const api = sessionStorage.getItem('dA') + version;
      const token = sessionStorage.getItem('dT');
      return axios.post(api+'/sets/'+id+'/image2', payload, {
                 headers: {
                   'Authorization' : 'Bearer '+token
                 }
      });
    }

  },


  file: {

    upload(url, formData) {
      return axios.post(url, 
          formData, {
          maxContentLength: 20971520,
          headers: {
              'Content-Type': 'multipart/form-data',
              //'Content-Length': files[0].size
          }
      });
    }

  },

}
